import React, { useContext, useEffect, useState } from 'react';
import '../css/CoursesHome.css';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { AccessLevel, AssessmentType, Course, Unit } from '../utils/Constants';
import { faPlay, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userHasPremiumSubscriptionPrivilege } from '@/utils/PermissionUtils';
import StateAccessor from '@/StateAccessor';
import CourseFetcherService from '@/utils/CourseFetcherService';
 import CoursesSidebar from './CoursesSidebar';

import { getAccessLevelString } from '@/utils/HelperFunctions';

interface CourseHomeProps {
  disabled?: boolean;
}

const CoursesHome: React.FC<CourseHomeProps> = () => {
  const { userSubscriptionTier } = useContext(StateAccessor);
  const { course_id } = useParams();
  const parsedCourseId = Number(course_id);
  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);
  const [course, setCourse] = useState<Course | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('Loading course...');

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const fetchedCourse = await CourseFetcherService.fetchCourseById(parsedCourseId);
        setCourse(fetchedCourse);
        setErrorMessage('');
      } catch (err) {
        setErrorMessage((err as Error).message);
      }
    };

    fetchCourse();
  }, [parsedCourseId]);

  if (errorMessage || !course) {
    return <div>{errorMessage}</div>;
  }

  if (!course) {
    return <div>Loading course...</div>;
  }

const handleUnitBoxClick = (moduleId: number, submodule_id: number, unit: Unit) => {

  if (unit.access_level === AccessLevel.ComingSoon) {
    // do nothing
    return;
  }
    navigate(`/app/learning/courses/${parsedCourseId}/modules/${moduleId}/submodules/${submodule_id}/units/${unit.unit_id}/challenge/${unit.challenge.challenge_id}`);
  };

  return (
  <>
    <div className="main-container">
      {/* Courses Home container */}
      <div className="courses-home-container">
        <div className='main-content'>
          <h1 className='course-home-title'>{course.title}</h1>
          {course.modules.map(module => (
            <div className='module' key={module.module_id}>
              <h2 className='module-home-title'>{module.title} module</h2>
              {module.submodules.map(submodule => (
                 <div className='submodule' key={submodule.submodule_id} id={`module-${module.module_id}-submodule-${submodule.submodule_id}`}>
                  <div className='submodule-header'>
                    <h3 className='submodule-home-title'>{submodule.title}</h3>
                    {submodule.estimated_completion_time_in_minutes !== null && (
                      <div className='completion-time'>
                        Estimated completion time: {submodule.estimated_completion_time_in_minutes} minutes
                      </div>
                    )}
                  </div>
                  <div className='units-container'>
                    {submodule.units.map(unit => {
                      if (unit.challenge.assessment_type === AssessmentType.SKIP_DUMMY_CHALLENGE) {
                        return null;
                      }

                      return (
                        <div 
                        className={`unit-box ${unit.access_level === AccessLevel.ComingSoon ? 'coming-soon' : ''}`}
                          key={unit.unit_id} 
                          onClick={() => handleUnitBoxClick(module.module_id, submodule.submodule_id, unit)}
                        >
                          <div className='unit-top-row'>
                            <div className='unit-title'>
                              <h5>{unit.title}</h5>
                            </div>
                            <button className={`start-challenge-button ${unit.challenge.difficulty}`}>
                              <FontAwesomeIcon
                                icon={unit.access_level === AccessLevel.Premium && !userHasPremiumSubscriptionPrivilege(userSubscriptionTier) ? faLock : faPlay}
                                style={{ fontSize: "1em", marginRight: "10px" }}
                              />
                              {getAccessLevelString(unit.access_level)}
                            </button>
                          </div>
                          <div className='challenge-info'>
                            <div className='tags'>
                              <span className={`challenge_difficulty_tag ${unit.challenge.difficulty}`}> {unit.challenge.difficulty} </span>
                              <span className={`challenge_type_tag ${unit.challenge.challenge_type}`}> {unit.challenge.challenge_type} </span>
                            </div>
                            <div className='time-limit'>
                              <span className='time-limit-label'>Time Limit:</span> {unit.challenge.time_limit_in_minutes} minutes
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* Sidebar container */}
      <div className='courses-sidebar-container'>
        <CoursesSidebar />
      </div>
    </div>

    <Tooltip id="premium-tooltip" />
    <Outlet />
  </>
);
};

export default CoursesHome;