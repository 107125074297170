import React, { useMemo } from 'react';
import { Button, Tooltip } from '@mui/material';
import { openUrlInNewTab } from '../../utils/HelperFunctions';
import './css/TargetCompanySelectionPage.css';
import {
  CompanyName,
  TechCompanyLogoUrl,
} from '../../utils/Constants';

export const DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL ='https://calendly.com/coditioning/company-specific-dedicated-interview-prep';
const META_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL = 'https://calendly.com/coditioning/meta-dedicated-interview-prep';
const GOOGLE_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL = 'https://calendly.com/coditioning/google-dedicated-interview-prep';
const MICROSOFT_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL = 'https://calendly.com/coditioning/msft-dedicated-interview-prep';
const AMAZON_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL = 'https://calendly.com/coditioning/amazon-dedicated-interview-prep';
  

// TypeScript Interface for Company Details
interface CompanyDetails {
  name: CompanyName | string;
  companyDedicatedPrepBookingUrl: string;
  logo: string | null;
  displayRank: number;
}

// Constants
const MAX_DISPLAY_RANK = Number.MAX_SAFE_INTEGER;

// Company Details Array
const COMPANY_DETAILS: CompanyDetails[] = [
  {
    name: CompanyName.FAANG,
    companyDedicatedPrepBookingUrl: DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: TechCompanyLogoUrl.FAANG,
    displayRank: 100,
  },
  {
    name: "Multiple Companies",
    companyDedicatedPrepBookingUrl: DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: null,
    displayRank: 200,
  },
  {
    name: CompanyName.META,
    companyDedicatedPrepBookingUrl: META_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: TechCompanyLogoUrl.META,
    displayRank: 300,
  },
  {
    name: CompanyName.AMAZON,
    companyDedicatedPrepBookingUrl: AMAZON_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: TechCompanyLogoUrl.AMAZON,
    displayRank: 400,
  },
  {
    name: CompanyName.GOOGLE,
    companyDedicatedPrepBookingUrl: GOOGLE_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: TechCompanyLogoUrl.GOOGLE_FULL_LOGO,
    displayRank: 500,
  },
  {
    name: CompanyName.MICROSOFT,
    companyDedicatedPrepBookingUrl: MICROSOFT_DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: TechCompanyLogoUrl.MICROSOFT,
    displayRank: 600,
  },
  {
    name: CompanyName.OTHER_COMPANY,
    companyDedicatedPrepBookingUrl: DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: null,
    displayRank: MAX_DISPLAY_RANK-1,
  },
  {
    name: "I want a Customized Session",
    companyDedicatedPrepBookingUrl: DEDICATED_INTERVIEW_PREP_SESSION_CALENDLY_URL,
    logo: null,
    displayRank: MAX_DISPLAY_RANK,
  },
];

const TargetCompanySelectionPage: React.FC = () => {
  // Memoize sorted company details
  const sortedCompanyDetails = useMemo(
    () => [...COMPANY_DETAILS].sort((a, b) => a.displayRank - b.displayRank),
    []
  );

  // Handle button click with error handling
  const handleCompanyClick = (url: string) => {
    try {
      openUrlInNewTab(url, 0);
    } catch (error) {
      console.error('Failed to open the URL:', error);
    }
  };

  return (
    <div className="company-selection-container">
      <h3 className="company-selection-heading">Which company or companies are you targeting?</h3>
      <div className="subtext"><i>We also cater to custom requests e.g. a multi-threading focused session</i></div>
      <div className="company-flex-layout">
        {sortedCompanyDetails.map((company) => (
          <div className="company-flex-item" key={company.name}>
            <Tooltip title={company.name} placement="top">
              <Button
                aria-label={`Select ${company.name}`}
                className="company-selection-button"
                onClick={() => handleCompanyClick(company.companyDedicatedPrepBookingUrl)}
              >
                {company.logo ? (
                  <img
                    src={company.logo}
                    alt={company.name}
                    className="company-booking-logo"
                    loading="lazy"
                  />
                ) : (
                  <span className="company-no-logo">{company.name}</span> // Render name if no logo
                )}
              </Button>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TargetCompanySelectionPage;
