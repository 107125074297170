import React, { useContext, useState } from 'react'; 
import ScheduleIcon from '@mui/icons-material/Schedule';
import PaymentIcon from '@mui/icons-material/Payment';
import { Button } from '@mui/material';
import './css/BookingAndPayment.css';
import { openUrlInNewTab } from '../../utils/HelperFunctions';
import StateAccessor from '../../StateAccessor';
import { MONEY_BACK_GUARANTEE_STATEMENT } from '../Terms';
import { NOT_SIGNED_IN_USER_USER_ID } from '@/utils/Constants';
import Tooltip from '@mui/material/Tooltip';
import { WatchSessionHighlightsModalPopupTriggerButton } from './HumanInterviewerCoach';
import Modal from '../../utils/Modal';
import TargetCompanySelectionPage from './TargetCompanySelectionPage';

export class Promo {
    static readonly CODE = 'GETTRULYREADYFORLESS';
    static readonly DISCOUNT_AS_INTEGER = 33; // 33% discount as an integer
}

interface PaymentButtonProps {
    url: string;
    userId: number;
    buttonLabel: string;
}

const PaymentButton: React.FC<PaymentButtonProps> = ({
    url,
    userId,
    buttonLabel,
}) => {
    return (
        <Button
            className="payment-button"
            variant="contained"
            color="secondary"
            onClick={() => openUrlInNewTab(url, userId)}
            style={{
                marginTop: '0.5rem',
                backgroundImage: 'linear-gradient(189deg, #ffff99, #fdd835)',
                color: 'black',
                textTransform: 'none',
            }}
        >
            <PaymentIcon style={{ marginRight: '0.5rem' }} />
            {buttonLabel}
        </Button>
    );
};

interface BookingOptionsProps {
    userId: number;
}

interface BookSessionButtonProps {
    userId?: number;
}

const BookSessionButton: React.FC<BookSessionButtonProps> = ({
    userId = NOT_SIGNED_IN_USER_USER_ID,
}) => {
    const [isCompanySelectionModalOpen, setIsCompanySelectionModalOpen] = useState(false);

   const handleOpenCompanySelectionModal = () => setIsCompanySelectionModalOpen(true);
   const handleCloseCompanySelectionModal = () => setIsCompanySelectionModalOpen(false);


    return (
        <>
            <Tooltip title="You can secure one or more slots without payment, and sort out payment in a separate step when ready (before the session starts)">
                <Button
                    className="book-session-button"
                    variant="contained"
                    color="primary"
                    onClick={handleOpenCompanySelectionModal} // Open the modal instead of navigating to the URL
                    style={{
                        textTransform: 'none',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        fontSize: '1.3rem',
                        width: 'auto',
                    }}
                    title="Book a dedicated interview prep session"
                >
                    <ScheduleIcon style={{ marginRight: '0.5rem' }} />
                    Book Session(s)
                </Button>
            </Tooltip>
                {isCompanySelectionModalOpen && (
                    <Modal
                    content={<TargetCompanySelectionPage />}
                    onClose={handleCloseCompanySelectionModal}
                    />
                )}
        </>
    );
};

const BookingOptions: React.FC<BookingOptionsProps> = ({ userId }) => {
    return (
        <>
            <div className="booking-options-container" id="booking-section">
                <h1>1) Book one or more sessions</h1>

                <BookSessionButton userId={userId} />
            </div>
        </>
    );
};




interface PaymentOptionsProps {
    userId: number;
}


const PaymentOptions: React.FC<PaymentOptionsProps> = ({ userId }) => {
    const BUNDLED_SESSIONS_PAYMENT_URL = "https://buy.stripe.com/4gw8xg2J12hBeCkfZe";
    const SINGLE_SESSION_PAYMENT_URL = "https://buy.stripe.com/9AQ9Bk1EX09t8dWdR7";
    const CODITIONING_360_PAYMENT_URL = "https://buy.stripe.com/cN2cNw6Zh3lF51KaEX";

    const standardPrepSessionFeatures = (
        <>
          <div className="service-feature">✅ 💯% money back guarantee</div>
          <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years)</div>
          <div className="service-feature">✅ Hire or No-Hire Decision (Know where you stand)</div>
          <div className="service-feature">✅ Tailored to your target companies</div>
          <div className="service-feature">✅ Detailed actionable feedback to level you up quickly</div>
          <div className="service-feature">✅ Real-time feedback (coaching sessions)</div>
          <div className="service-feature">✅ Access to private recording of the session (if you opt in)</div>
        </>
      );

      const renderPriceTag = (officialPrice: number) => {

        const promotionalPrice: number = parseFloat((officialPrice * (1 - Promo.DISCOUNT_AS_INTEGER / 100)).toFixed(2));
        return (
            <div className="price-tag">
            <del>${officialPrice}</del> ${promotionalPrice} per session (1hr)
            <div className="price-tag-subtitle"><strong>Save {Promo.DISCOUNT_AS_INTEGER}%</strong> with code <strong>{Promo.CODE}</strong></div>
          </div>
        );
      };

    return (
        <>
            <h1 id='payment-section'> 2) Pay </h1>
            <div className="payment-options-grid">
                
                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Bundled Sessions</h1>
                        <span className='recommendedTag'> Cheapest</span>
                    </div>

                    
                    {renderPriceTag(150)}
                    <PaymentButton
                        url={BUNDLED_SESSIONS_PAYMENT_URL}
                        userId={userId}
                        buttonLabel="Purchase Bundled Sessions"
                    />
                    <WatchSessionHighlightsModalPopupTriggerButton buttonLabelHtml="View Sample Sessions" />
                    

                    {/* Features */}
                    {standardPrepSessionFeatures}
                    <div className="service-feature">✅ Save when you book multiple sessions</div>
                    <div className="service-feature">✅ Mix & match different session domains (system design, coding, behavioural etc)</div>

                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Single Session</h1>
                        <span className='recommendedTag'> Best for sampling</span>
                    </div>

                    {renderPriceTag(170)}


                    <PaymentButton
                        url={SINGLE_SESSION_PAYMENT_URL}
                        userId={userId}
                        buttonLabel='Purchase Single Session'
                    />
                    <WatchSessionHighlightsModalPopupTriggerButton buttonLabelHtml="View Sample Sessions" />

                    {/* Features */}
                    {standardPrepSessionFeatures}

                    
                </div>

                <div className="booking-type-container">
                    <div className='feature-header'>
                        <h1>Coditioning 360°</h1>
                        <span className='recommendedTag'>Comprehensive</span>
                    </div>
                    
                    {renderPriceTag(268)}
                    <PaymentButton
                        url={CODITIONING_360_PAYMENT_URL}
                        userId={userId}
                        buttonLabel="Purchase Coditioning 360°"
                    />
                    <WatchSessionHighlightsModalPopupTriggerButton buttonLabelHtml="View Sample Sessions" />

                    <div className="service-feature">✅ 💯% money-back guarantee*</div>
                    <div className="service-feature">✅ Pair with a FAANG+ senior engineer (10+ years of experience)</div>
                    <div className="service-feature">✅ End-to-end guidance</div>
                    <div className="service-feature">✅ Access to the Coditioning Lounge (Private Slack Server)</div>
                    <div className="service-feature">✅ Full support outside coaching/mock sessions</div>
                    <div className="service-feature">✅ Tailored interview prep roadmap</div>
                    <div className="service-feature">✅ 5+ hour-long sessions</div>
                    <div className="service-feature">✅ Target company-specific tailoring</div>
                    <div className="service-feature">✅ Rigorous Data Structures & Algorithms Strengths & Weaknesses Diagnostic</div>
                    <div className="service-feature">✅ Behavioral Response Crafting</div>
                    <div className="service-feature">✅ At least 1 Strategy Session</div>
                    <div className="service-feature">✅ At least 3 Mock Interviews</div>
                    <div className="service-feature">✅ Team-matching & negotiation guidance</div>
                    <div className="service-feature">✅ Full access to Coditioning Premium</div>
                    <div className="service-feature">✅ CV Review</div>

                    
                    
                </div>

            </div>

            <div className="money-back-guarantee">
                <p>
                    <a href="/terms/#money-back-guarantee">
                        <strong>*{MONEY_BACK_GUARANTEE_STATEMENT}</strong>
                    </a>
                </p>
            </div>
        </>
    );
};


const BookingAndPayment: React.FC = () => {
    const { userId } = useContext(StateAccessor);

    return (
        <div className="booking-and-payment-container landing-page-row">
            <BookingOptions userId={userId} />
            <hr />
            <PaymentOptions userId={userId} />


        </div>
    );
};
export { BookingAndPayment as default, BookSessionButton };
