import React, { useState, useContext } from "react";
import {
  signInWithEmailAndPassword as signIn,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Typography, Container, Divider } from "@mui/material";
import { Form, StyledTextField, StyledButton, ErrorText, AuxiliaryButton } from "../utils/FormStyles";
import { useNavigate } from "react-router-dom";
import StateAccessor from "../StateAccessor";
import { LANDING_PAGE_UPON_SIGN_IN_PATH, SIGN_UP_PAGE_PATH } from "../utils/Constants";
import { cacheUserIdByEmailMapping, getUserIdByEmailFromBackend } from "../utils/HelperFunctions";
import { FcGoogle } from "react-icons/fc";
import { signInOrSignUpWithGmail } from "@/utils/AuthHelpers";
import { StyledDivider } from "./SignUpPage";

const SignInPage = () => {
  const { setUserEmail, setUserId } = useContext(StateAccessor);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSigningIn, setIsSigningIn] = useState(false);

  const initialiseUsersAccess = async (email: string, userId: number) => {
    // set user details
    cacheUserIdByEmailMapping(email, userId);
    setUserEmail(email);
    setUserId(userId);
    navigate(LANDING_PAGE_UPON_SIGN_IN_PATH);
  };



  const signInWithEmailAndPassword = async (email: string, password: string) => {
    let userId: number | null = null;

    // First try-catch for fetching user details
    try {
      userId = await getUserIdByEmailFromBackend(email, 3);  // 3 retries as an example
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError("Failed to fetch user details, please contact support if your details are correct");
      return;  // Return early to avoid proceeding to the sign-in process
    }

    // Second try-catch for signing in with email and password
    try {
      await signIn(auth, email, password);
      initialiseUsersAccess(email, userId);
    } catch (error) {
      console.error("Error signing in:", error);
      setError("Invalid email-password combination");
    }
  };


  const navigateToPasswordResetPage = async () => {
    navigate("/passwordReset")
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSigningIn(true);
    // Validate email using a more comprehensive regular expression
    if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setError("Invalid email");
      return;
    }
    signInWithEmailAndPassword(email, password);
    setIsSigningIn(false);
  };

  const handleGmailSignIn = () => {
    signInOrSignUpWithGmail({
      isSignUp: false, // Sign-In scenario
      navigate,
      setError,
      setIsSigningInorSigningUp: setIsSigningIn,
    });
  };

  const navigateToSignUpPage = () => {
    navigate(SIGN_UP_PAGE_PATH);
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center">
        Sign In
      </Typography>
      {error && <ErrorText>{error}</ErrorText>}
      <StyledButton
        onClick={handleGmailSignIn}
        style={{ textTransform: 'none' }}
        variant="outlined"
        fullWidth
        startIcon={<FcGoogle />}
      >
        Sign in with Google
      </StyledButton>
      <StyledDivider>
        <span>or continue with</span>
      </StyledDivider>
      <Form onSubmit={handleSubmit}>
        <StyledTextField
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          variant="outlined"
          fullWidth
        />
        <StyledTextField
          label="Password"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          variant="outlined"
          fullWidth
        />

        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSigningIn}
        >
          {isSigningIn ? "Signing In..." : "Sign In"}
        </StyledButton>
        <StyledButton onClick={navigateToSignUpPage} variant="outlined" color="primary" fullWidth>
          Don't have an account yet? Sign Up Here
        </StyledButton>
        <AuxiliaryButton onClick={navigateToPasswordResetPage}>
          Forgot password?
        </AuxiliaryButton>

      </Form>
    </Container>
  );
};


export default SignInPage;
