import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Container, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { ErrorText } from "../utils/FormStyles";
import { doc, setDoc, arrayUnion, getDoc } from "firebase/firestore";
import "../css/SignUpPage.css";
import log from "../utils/Logger";
import { DEFAULT_EMAIL_JS_PUBLIC_KEY, LANDING_PAGE_UPON_SIGN_IN_PATH, NavigationUrlParamTags, SUBSCRIPTIONS_PAGE_PATH } from "../utils/Constants";
import emailjs from 'emailjs-com';
import { CODITIONING_OUTLOOK_SERVICE_ID, WELCOME_EMAIL_TEMPLATE_ID, } from '../utils/Constants';
import { FcGoogle } from "react-icons/fc";
import { addUserEmailToFirebaseUsersCollectionIfNew } from "@/externalLayerAccessor/firebaseAccessor";
import { signInOrSignUpWithGmail } from "@/utils/AuthHelpers";
import { createUserInBackend, logToBackendLogFile } from "@/externalLayerAccessor/BackEndRequests";

const EMAIL_JS_PUBLIC_KEY = process.env.EMAIL_JS_PUBLIC_KEY || DEFAULT_EMAIL_JS_PUBLIC_KEY;

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiTextField-root, & .MuiButton-root': {
        marginTop: theme.spacing(2),
    },
}));


export const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: `${theme.spacing(2)} 0`,
    width: "100%",
    textAlign: "center",
    "&::before, &::after": {
        borderColor: grey[400], // Grey line color
    },
    "& span": {
        padding: "0 8px", // Add padding around the text
        color: grey[400], // Grey text color
        fontSize: "16px",
    }
}));

const DEFAULT_MVP_PASSWORD: string = `P-22wd-346gF!_${Math.random().toString(36).substring(2, 10)}`;

const SignUpPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password] = useState(DEFAULT_MVP_PASSWORD);
    const [confirmPassword] = useState(DEFAULT_MVP_PASSWORD);
    const [username, setUsername] = useState("");
    const [invitationCode] = useState("");
    const [error, setError] = useState("");
    const [userCount, setUserCount] = useState(Number.MAX_SAFE_INTEGER); // Set to max safe integer to ensure waitlist mode is enabled by default
    const [isWaitlistMode, setIsWaitlistMode] = useState(true);
    const MAXIMUM_ALLOWED_USERS: Readonly<number> = 1152;
    const [isUserAddedToWaitlist, setIsUserAddedToWaitlist] = useState(false);
    const [isSigningUp, setIsSigningUp] = useState(false);


    useEffect(() => {
        setIsWaitlistMode(userCount >= MAXIMUM_ALLOWED_USERS);
    }, [userCount]);

    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const usersDocRef = doc(db, "alpha-phase", "users");
                const usersSnapshot = await getDoc(usersDocRef);

                if (usersSnapshot.exists()) {
                    // Get the emails array from the snapshot data
                    const emailsArray = usersSnapshot.data().emails;
                    setUserCount(emailsArray.length);
                } else {
                    //log.error("Error fetching user count: users document not found");
                    setError("An internal server error occurred. Please try again later.");
                }
            }
            catch (error) {
                log.error("Error fetching user count:", error);
                setError("An internal server error occurred. Please try again later.");
            }
        };

        fetchUserCount();
    }, []);



    const handleWaitlistJoin = async (email: string) => {
        try {
            const waitlistDocRef = doc(db, "alpha-phase", "waitlist");

            // Fetch the "waitlist" document from the "alpha-phase" collection
            const docSnapshot = await getDoc(waitlistDocRef);

            // Check if the "waitlist" document exists
            if (docSnapshot.exists()) {
                // If the document exists, update the "emails" array by adding the new email using arrayUnion
                await setDoc(waitlistDocRef, {
                    emails: arrayUnion(email),
                }, { merge: true }); // Merge the existing fields with the new "emails" array
            } else {
                // If the document doesn't exist, create a new document with the "emails" array containing the provided email
                await setDoc(waitlistDocRef, {
                    emails: [email],
                });
            }
            setIsUserAddedToWaitlist(true);
            log.info("User added to the waitlist successfully");
        } catch (error) {
            setError("Error adding user to the waitlist. Please try again.");
            log.error("Error adding user to the waitlist:", error);
        }
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        log.debug("Handling signup form submission");
        if (isWaitlistMode) {
            handleWaitlistJoin(email);
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]{8,}$/)) {
            setError("Invalid password. Must be at least 8 characters and contain at least one letter and one number.");
            return;
        }


        handleSubscriptionSignUp(email, password);
    };

    const sendWelcomeEmail = async (email: string, password: string): Promise<void> => {
        const templateParams = {
            email: email,
            password: password
        };

        await emailjs.send(
            CODITIONING_OUTLOOK_SERVICE_ID,
            WELCOME_EMAIL_TEMPLATE_ID,
            templateParams,
            EMAIL_JS_PUBLIC_KEY,
        );
    }

    //////////////////////////////////////////////////////////
    /// **Note** Any changes to the handleSignUp function should be strongly considered for reflection within the signin component which also
    /// happens to sign-up users that are signing for the first time e.g. via google/gmail sign-in (there may be others)
    // in the future we should unify the sign-in and sign-up component, so that there is a signle sign-up handler reused everywhere, and defined once!
    //////////////////////////////////////////////////////////
    const handleSignUp = async (email: string, password: string) => {
        try {
            setIsSigningUp(true);

            log.debug(`Handling signup for email: ${email}`);

            const userData = {
                userName: username,
                email: email,
                password: password,
                invitationCode: invitationCode,
            }

            const isBackendSuccess = await createUserInBackend(userData);

            if (isBackendSuccess) {
                // @ts-ignore
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                log.info("User signed up successfully");

                // Navigate the user to landing page
                navigate(`${LANDING_PAGE_UPON_SIGN_IN_PATH}?source_component=${NavigationUrlParamTags.SIGN_UP_COMPONENT}`);

                // Add the user's email to the users document in the alpha-phase collection asynchronously
                const userEmail = userCredential?.user?.email;
                if (!userEmail) {
                    throw new Error("User email is unexpectedly null or undefined after sign-up.");
                }

                // Add the user's email to the backend
                await addUserEmailToFirebaseUsersCollectionIfNew(userEmail);

                // send welcome email
                sendWelcomeEmail(email, password); // asynchronous call to send welcome email //TODO: include password
            }else{
                throw new Error("An error occurred while creating your account. Please try again or contact support.");
            }
        } catch (error) {
            setError("An error occurred, please try again, ensuring you have entered valid values. Otherwise, contact support");
            logToBackendLogFile(`User with email: ${email} had an error signing up: ${error}`, "error");
        }
        setIsSigningUp(false);
    };

    const handleSubscriptionSignUp = async (email: string, password: string) => {
        await handleSignUp(email, password);

        // navigate to premium subscription page
        navigate(SUBSCRIPTIONS_PAGE_PATH);
    }

    const handleGmailSignIn = () => {
        signInOrSignUpWithGmail({
            isSignUp: true, // Sign-Up scenario
            navigate,
            setError,
            setIsSigningInorSigningUp: setIsSigningUp,
        });
    };

    const navigateToSignInPage = () => {
        navigate("/signin");
    };

    return (
        <StyledContainer maxWidth="xs">
            {!isUserAddedToWaitlist ? (
                <>
                    {!isWaitlistMode && email && (<p style={{ color: "#1976d2" }}>Your password will be sent to your email address</p>)}
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    {error && <ErrorText>{error}</ErrorText>}
                    <Button onClick={handleGmailSignIn} style={{
                        textTransform: 'none',
                    }} variant="outlined" fullWidth startIcon={<FcGoogle />}>
                        Sign up with Google
                    </Button>
                    <StyledDivider>
                        <span>or continue with</span>
                    </StyledDivider>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={
                                (event) => {
                                    setEmail(event.target.value);
                                    setUsername(event.target.value);
                                }
                            }
                        />
                        {!isWaitlistMode && (
                            <>
                                {/* Other form fields like password have been removed to reduce friction, see commit history to revert */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isSigningUp}
                                >
                                    {isSigningUp ? "Signing Up..." : "Sign Up"}
                                </Button>
                            </>
                        )}
                        {isWaitlistMode && (
                            <Button
                                onClick={() => handleWaitlistJoin(email)}
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Join Waitlist
                            </Button>
                        )}
                        <Button
                            onClick={navigateToSignInPage}
                            fullWidth
                            variant="outlined"
                            color="primary"
                        >
                            Already Have an Account? Sign In Here
                        </Button>
                    </form>
                    <p className="sign-up-agreement-text">
                        By signing up, you agree to our{' '}
                        <Link to="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</Link>, as well as our{' '}
                        <Link to="/privacy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.
                    </p>
                </>
            ) : (
                <p>
                    <i>
                        You have been added to the waitlist. You will receive an email notifying you of when you can sign up. Please keep an eye on your junk folder.
                    </i>
                </p>
            )}
        </StyledContainer>
    );

};

export default SignUpPage;
