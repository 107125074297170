import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import InterviewPage from "./pages/InterviewPage";
import ProtectedLayout from "./utils/ProtectedLayout";
import PublicLayout from "./utils/PublicLayout";
import "./styles.css";
import PasswordResetPage from "./pages/PasswordResetPage";
import FeedbackPage from "./pages/FeedbackPage";
import InterviewSelectionPage from "./pages/InterviewSelectionPage";

import About from "./pages/About";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

import StateAccessor from "./StateAccessor";
import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import Careers from "./pages/Careers/Careers";
import Dashboard from "./pages/Dashboard";
import PhasesPage from "./pages/Phases_beta";
import JourneyPage from "./pages/Journey_beta";
import { CourseProvider } from "./utils/CoursesContext";
import Courses from "./pages/Courses";
import CoursesHome from "./pages/CourseHome";
import { AssessmentProvider } from "./utils/AssessmentContext";
import Assessments from "./pages/Assessments";
import CVService from "./CVService";
import {
  BLOG_PAGE_PATH,
  CV_SERVICES_PAGE_PATH,
  HUMAN_INTERVIEWER_COACH_PATH,
  AI_INTERVIEW_SELECTION_PAGE_PATH,
  LEARNING_PAGE_PATH,
  LEARNING_PATH_GENERATOR_PATH,
  LEARNING_PATH_PATH,
  NOT_SIGNED_IN_USER_USER_EMAIL,
  NOT_SIGNED_IN_USER_USER_ID,
  SIGN_IN_PAGE_PATH,
  SIGN_UP_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  JOURNEY_PAGE_PATH,
  PHASES_PAGE_PATH,
  CRASH_COURSE_PAGE_PATH,
  AI_INTERVIEW_PAGE_PATH,
  DIAGNOSTICS_PAGE_PATH,
  CODING_SANDBOX_PATH,
  MOCK_INTERVIEW_PAGE_PATH,
  SUBSCRIPTIONS_PAGE_PATH,
  WORKSHOPS_PAGE_PATH,
  LEARNING_PAGE_COURSES_SUB_PATH,
} from "./utils/Constants";
import BlogHome from "./blog/BlogHome";
import LearningPathGenerator from "./pages/LearningPathGenerator";
import LearningPathComponent from "./pages/LearningPath";
import {
  cacheUserIdByEmailMapping,
  getUserIdByEmailFromBackend,
  getUserIdByEmailFromCache,
} from "./utils/HelperFunctions";
import HomePage from "./pages/HomePage";
import HumanInterviewerCoach from "./pages/HumanInterviewerCoach/HumanInterviewerCoach";
import PublicWithMobileOptimisationWarningLayout from "./utils/PublicWithMobileOptimisationWarningLayout";
import LearningMenu from "./pages/LearningMenu";
import CrashCourseMenu from "./pages/CrashCourseMenu";
import DiagnosticsMenu from "./pages/DiagnosticsMenu";
import CodingSandbox from "./pages/CodingSandbox";
import MockInterviewMenu from "./pages/MockInterviewMenu";
import { UserSubscription, UserSubscriptionTier } from "./externalLayerAccessor/models/firebaseModels";
import { getSubscriptionByUserEmail } from "./utils/PermissionUtils";
import Subscriptions from "./pages/AccountManagement/Subscriptions";
import WorkshopsMenu from "./pages/WorkshopsMenu";

const App: React.FC = () => {
  const [userId, setUserId] = useState(NOT_SIGNED_IN_USER_USER_ID);
  const [userName, setUserName] = useState("User");
  const [userSubscriptionTier, setUserSubscriptionTier] = useState<UserSubscriptionTier>(UserSubscriptionTier.FREE);
  const [userEmail, setUserEmail] = useState(NOT_SIGNED_IN_USER_USER_EMAIL);

  useEffect(() => {
    const handleAuthStateChange = async (user: any) => {
      if (user) {

        // set userEmail
        const userEmailFromFirebase = user.email || "";
        setUserEmail(userEmailFromFirebase);

        // set userId
        const cachedUserId = getUserIdByEmailFromCache(userEmailFromFirebase);
        if (cachedUserId) {
          setUserId(cachedUserId);
        } else {
          const userId = await getUserIdByEmailFromBackend(
            userEmailFromFirebase
          );
          setUserId(userId);
          cacheUserIdByEmailMapping(userEmailFromFirebase, userId);
        }


        //set userSubscriptionTier
        const subscription: UserSubscription = await getSubscriptionByUserEmail(userEmailFromFirebase);
        setUserSubscriptionTier(subscription.subscriptionTier);


        // set userName
        if (subscription.displayName) {
          setUserName(subscription.displayName);
        }

      } else {
        setUserEmail(NOT_SIGNED_IN_USER_USER_EMAIL);
        setUserId(NOT_SIGNED_IN_USER_USER_ID);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) =>
      handleAuthStateChange(user)
    );

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <StateAccessor.Provider
      value={{
        userId,
        setUserId,
        userName,
        setUserName,
        userEmail,
        setUserEmail,
        userSubscriptionTier,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<HomePage />} />
            <Route path={HUMAN_INTERVIEWER_COACH_PATH} element={<HumanInterviewerCoach />} />
            <Route path={SIGN_IN_PAGE_PATH} element={<SignInPage />} />
            <Route path={SIGN_UP_PAGE_PATH} element={<SignUpPage />} />
            <Route path="passwordReset" element={<PasswordResetPage />} />
            <Route path="feedback" element={<FeedbackPage />} />
            <Route path="careers" element={<Careers />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path={`${BLOG_PAGE_PATH}/:postId`} element={<BlogHome />} />
            <Route path={`${BLOG_PAGE_PATH}/:postId/:slug`} element={<BlogHome />} />
            <Route path={BLOG_PAGE_PATH} element={<BlogHome />} />
            <Route path={DASHBOARD_PAGE_PATH} element={<Dashboard userName={userName} />} />
            <Route path={JOURNEY_PAGE_PATH} element={<JourneyPage />} />
            <Route path={PHASES_PAGE_PATH} element={<PhasesPage />} />
            <Route path={WORKSHOPS_PAGE_PATH} element={<WorkshopsMenu />}/>
          </Route>

          {/* start of new route */}
          <Route path="app" element={<PublicLayout />}>
            <Route index element={<HomePage />} />
            <Route
              path={LEARNING_PATH_GENERATOR_PATH}
              element={<LearningPathGenerator />}
            />
            <Route
              path={LEARNING_PATH_PATH}
              element={<LearningPathComponent />}
            />
            <Route path={CV_SERVICES_PAGE_PATH} element={<CVService />} />

            <Route
              path={LEARNING_PAGE_PATH}
              element={
                <CourseProvider>
                  <AssessmentProvider>
                    <Outlet />
                  </AssessmentProvider>
                </CourseProvider>
              }
            >
              <Route index element={<LearningMenu />} />
            </Route>

            <Route
              path={CRASH_COURSE_PAGE_PATH}
              element={<CrashCourseMenu />}
            />

            <Route
              path={MOCK_INTERVIEW_PAGE_PATH}
              element={<MockInterviewMenu />}
            />

            <Route
              path={DIAGNOSTICS_PAGE_PATH}
              element={
                <AssessmentProvider>
                  <Outlet />
                </AssessmentProvider>
              }
            >
              <Route index element={<DiagnosticsMenu />} />
              <Route path="assessments" element={<Outlet />}>
                <Route path=":assessment_id" element={<Assessments />} />
              </Route>
            </Route>

            <Route
              path={AI_INTERVIEW_SELECTION_PAGE_PATH}
              element={<InterviewSelectionPage />}
            />

          </Route>
          {/* end of route */}

          {/* start of new route */}
          <Route path="app" element={<PublicWithMobileOptimisationWarningLayout />}>
            <Route index element={<HomePage />} />


            <Route
              path={LEARNING_PAGE_PATH}
              element={
                <CourseProvider>
                  <AssessmentProvider>
                    <Outlet />
                  </AssessmentProvider>
                </CourseProvider>
              }
            >
              <Route path="assessments" element={<Outlet />}>
                <Route path=":assessment_id" element={<Assessments />} />
              </Route>
              <Route path={LEARNING_PAGE_COURSES_SUB_PATH} element={<Outlet />}>
                <Route path=":course_id" element={<CoursesHome />} />
                <Route
                  path=":course_id/modules/:module_id/submodules/:submodule_id/units/:unit_id/challenge/:challenge_id"
                  element={<Courses />}
                />
              </Route>
            </Route>


            <Route
              path={AI_INTERVIEW_PAGE_PATH}
              element={<InterviewPage />}
            />

            <Route
              path={CODING_SANDBOX_PATH}
              element={<CodingSandbox />}
            />

            <Route path={SUBSCRIPTIONS_PAGE_PATH} element={<Subscriptions />} />

          </Route>
          {/* end of route */}

          <Route path="app" element={<ProtectedLayout />}>
            <Route index element={<HomePage />} />
          </Route>
        </Routes>
      </Router>
    </StateAccessor.Provider>
  );
};

export default App;