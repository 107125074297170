import React, { useEffect, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import CodeStorage from "./CodeStorage";
import { MinusIcon, PlusIcon } from "lucide-react";
import "@/css/CodeEditor.css";

/**
 * Ensures a value is within the specified minimum and maximum bounds.
 * @param value - The number to enforce bounds on.
 * @param min - The minimum allowable value.
 * @param max - The maximum allowable value.
 * @returns A value within the range [min, max].
 */
function enforceFontSizeBounds(value: number, min: number, max: number): number {
  return Math.min(max, Math.max(min, value));
}

enum Theme {
  DARK = "vs-dark",
  LIGHT = "vs-light",
  HIGH_CONTRAST = "hc-black",
}

// Define constants for localStorage keys and min/max font size
const CODE_EDITOR_THEME: Readonly<string> = "code-editor-theme";
const CODE_EDITOR_FONT_SIZE: Readonly<string> = "code-editor-font-size";
const MIN_FONT_SIZE: Readonly<number> = 12;
const MAX_FONT_SIZE: Readonly<number> = 32;
const DEFAULT_FONT_SIZE: Readonly<number> = 16;

// Function to initialize the theme from localStorage or fallback to default
function initializeTheme(): Theme {
  const storedTheme = localStorage.getItem(CODE_EDITOR_THEME);
  if (storedTheme && Object.values(Theme).includes(storedTheme as Theme)) {
    return storedTheme as Theme;
  }
  return Theme.DARK;
}

interface Props {
  value: string;
  language: string;
  codeEditorHeightInPx: number;
  userId: string;
  taskId: string;
  onChange: (value: string) => void;
  shouldPersistCode?: boolean;
}

const CodeEditor: React.FC<Props> = ({
  value,
  language,
  codeEditorHeightInPx,
  userId,
  taskId,
  onChange,
  shouldPersistCode: useCodeStorage = false,
}) => {
  const [theme, setTheme] = useState<Theme>(Theme.DARK);
  const [fontSizeInPixels, setFontSizeInPixels] = useState<number>(DEFAULT_FONT_SIZE);

  useEffect(() => {
    // Initialize the theme from localStorage
    const themeFromStorage = initializeTheme();
    setTheme(themeFromStorage);
  }, []);

  useEffect(() => {
    // Initialize the font size from localStorage
    const storedFontSize = localStorage.getItem(CODE_EDITOR_FONT_SIZE);
    const parsedFontSize = storedFontSize && !isNaN(Number(storedFontSize))
      ? parseInt(storedFontSize, 10)
      : DEFAULT_FONT_SIZE;
    setFontSizeInPixels(enforceFontSizeBounds(parsedFontSize, MIN_FONT_SIZE, MAX_FONT_SIZE));
  }, []);

  useEffect(() => {
    // Persist theme in localStorage
    localStorage.setItem(CODE_EDITOR_THEME, theme);
  }, [theme]);

  useEffect(() => {
    // Persist font size in localStorage
    localStorage.setItem(CODE_EDITOR_FONT_SIZE, fontSizeInPixels.toString());
  }, [fontSizeInPixels]);

  // Helper function to get CSS class names based on theme
  /**
   * Generates a class name string based on the provided theme and base class.
   * @param theme - The current editor theme.
   * @param baseClass - The base CSS class to append the theme-specific class.
   * @returns A combined class name string.
   */
  const getCSSClassNameByCodeEditorTheme = (theme: Theme, baseClass: string): string => {
    switch (theme) {
      case Theme.LIGHT:
        return `${baseClass} light`;
      case Theme.HIGH_CONTRAST:
        return `${baseClass} high-contrast`;
      default:
        return `${baseClass} dark`;
    }
  };

  const fontSizeControlButtonClass = getCSSClassNameByCodeEditorTheme(theme, "icon-button text-white");

  useEffect(() => {
    // Only load stored code if useCodeStorage is true
    if (!useCodeStorage) return;

    // Load stored code
    let isMounted = true;

    const loadCode = async () => {
      try {
        const storedCode = await CodeStorage.fetchCode(userId, taskId, language);
        if (isMounted && storedCode && storedCode !== value) onChange(storedCode);
      } catch (error) {
        console.error("Error fetching code:", error);
      }
    };

    loadCode();

    return () => {
      isMounted = false;
    };
  }, [userId, taskId, language, useCodeStorage]);

  //@ts-ignore
  const handleEditorDidMount = (editor, monaco: typeof editor) => {
    console.log("Editor did mount", editor);
    editor.focus();
  };

  //@ts-ignore
  const handleChange = (newValue: string | undefined, e: monaco.editor.IModelContentChangedEvent) => {
    if (newValue !== undefined) {
      onChange(newValue);

      // Only save code if useCodeStorage is true
      if (useCodeStorage) {
        CodeStorage.debouncedSave(userId, taskId, language, newValue); //TODO MAJOR: consider if error-handling is needed here handle appropriately if async, happy to gracefully ignore execption if it fails
      }
    }
  };

  return (
    <div className="code-editor-container" style={{ height: '100%', width: '100%', minHeight: codeEditorHeightInPx }}>
      <div className={getCSSClassNameByCodeEditorTheme(theme, "editor-controls")} >
        <select
          value={theme}
          aria-label="Select Theme"
          onChange={(e) => setTheme(e.target.value as Theme)}
          className={getCSSClassNameByCodeEditorTheme(theme, "theme-selector")}
        >
          <option value={Theme.DARK}>Dark</option>
          <option value={Theme.LIGHT}>Light</option>
          <option value={Theme.HIGH_CONTRAST}>High Contrast</option>
        </select>
        <div>
          <MinusIcon size={32} className={fontSizeControlButtonClass} aria-label="Decrease Font Size" onClick={() =>
            setFontSizeInPixels((prev) => enforceFontSizeBounds(prev - 1, MIN_FONT_SIZE, MAX_FONT_SIZE))
          } />
          <span>{fontSizeInPixels}</span>
          <PlusIcon size={32} className={fontSizeControlButtonClass} aria-label="Increase Font Size" onClick={() =>
            setFontSizeInPixels((prev) => enforceFontSizeBounds(prev + 1, MIN_FONT_SIZE, MAX_FONT_SIZE))
          } />
        </div>
      </div>
      <MonacoEditor
        height="100%"
        width="100%"
        language={language}
        theme={theme}
        value={value}
        onChange={handleChange}
        onMount={handleEditorDidMount}
        options={{
          fontSize: fontSizeInPixels,
        }}
      />
    </div>
  );
};

export default CodeEditor;