import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/GenericLearningHome.css';
import CoursesMenu from './CoursesMenu';
import Progress from '../utils/Progress';
import LearningHomeSubMenu from './LearningHomeSubMenu';
import { logToBackendLogFile } from '../externalLayerAccessor/BackEndRequests';
import StateAccessor from '../StateAccessor';

import { CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG, WORKSHOPS_PAGE_PATH } from '../utils/Constants';
import { BannerButtonProps } from '../utils/BannerButton';
import BackgroundCoachingAndInstructorImg from "../img/learning_images/coaching-cover-art-warrior-being-coached.webp"
import BackgroundAlgorithmFoundationsImg from "../img/learning_images/foundations_course_background.jpg"
import BackgroundAlgorithmIntermediateImg from "../img/learning_images/intermediate_course_background.jpg"
import OptimizeYourInterviewPerformanceImg from "../img/learning_images/advanced_course_background.jpg"
import SystemDesignCrashCourseImg from "../img/learning_images/system-design-cover-art-potion-mixer.webp.webp"
import HumanMockInterviewImg from "../img/features_img/medieval_knight_trial.webp"
import AIMockInterviewImg from "../img/features_img/AiInterviewer.webp"
import AccessInterviewReadinessImg from "../img/learning_images/warrior_ready_to_embark.webp"

export class ActiveTiles {
  private activeTitles: Map<LearningComponents, number>;
  private firstActiveComponent: LearningComponents | null;

  /*
  Constructore accepts an array of tuples where the first element is the LearningComponent and the second element is the rank
  The rank dictates the order in which the components should be displayed, and should ideally be used to enforce this order
  */
  constructor(initialData: [LearningComponents, number][]) {
    this.activeTitles = new Map(initialData);

    if (initialData.length > 0) {
      this.firstActiveComponent = initialData[0][0]; // initialData[0] returns the first tuple in the array, and [0] returns the first element of the tuple
    } else {
      this.firstActiveComponent = null;
    }
  }

  getRankByLearningComponent(component: LearningComponents): number {
    if (this.activeTitles.has(component)) {
      return this.activeTitles.get(component)!;
    } else {
      return -1;
    }
  }

  getFirstActiveComponent(): LearningComponents | null {
    return this.firstActiveComponent;
  }

  isEmpty(): boolean {
    return this.activeTitles.size === 0;
  }

  has(component: LearningComponents): boolean {
    return this.activeTitles.has(component);
  }
}

interface LearningHomeProps {
  activeTitles: ActiveTiles
  disabled?: boolean;
  banner?: BannerButtonProps | null;
}

const MemoizedLearningHomeSubMenu = React.memo(LearningHomeSubMenu);


export enum LearningComponents {
  /*
  *************** IMPORTANT ***************
  Until we have a better solution
  If you add a new component here you need to update cssClassNames so that the component has a background image.
  *************** IMPORTANT ***************
  */
  AssessInterviewReadiness = 'Assess your Interview Readiness',
  IdentifyKnowledgeGaps = 'Identify Weaknesses & Knowledge Gaps',
  LearningPath = 'Custom Learning',
  ExploreContent = 'Explore Content',
  Coaching = 'One-to-one Coaching',
  InstructorLedCrashCourses = 'Instructor-led Crash Courses',
  HumanMockInterviews = 'Mock Interviews',
  AIMockInterviews = 'AI Mock Interviews',
  SideQuests = 'Side Quests',
  StudyGroups = 'Study Groups',
  Leaderboard = 'Leaderboard',
  Revision = 'Revision',
  Achievements = 'Achievements',

  /*Crash Courses*/
  AlgosAndDataStructuresCrashCourse = 'Algorithms & Data Structures (Foundations)',
  AlgosAndDataStructuresCrashCourseIntermediate = 'Advanced Techniques for Coding Interviews', // will cover things like pattern spotting, coming up with optimal solutions, 
  SystemDesignCrashCourse = 'Mastering System Design Interviews',
  OptimizeYourInterviewPerformanceCrashCourse = 'Slaying the Technical Interview', // this is about interviewing skills & preparation strategies

  /*Workshops*/
  SystemDesignWorkShop = 'System Design Workshop',
  CodingAndBehaviouralWorkShop = 'Coding and Behavioural Workshop',

  /*Courses*/
  SystemDesignCourse = 'System Design',
  None = 'None',
}

const LearningModeCoverArt: Record<
  LearningComponents,
  { imgSrc: string; alt: string }
> = {
  [LearningComponents.AssessInterviewReadiness]: {
    imgSrc: AccessInterviewReadinessImg,
    alt: 'A warrior assessing their readiness for an interview.',
  },
  [LearningComponents.IdentifyKnowledgeGaps]: {
    imgSrc: 'https://coditioning3.files.wordpress.com/2023/12/dallc2b7e-2023-12-09-01.12.01-a-black-and-white-manga-style-image-set-in-a-setting-similar-to-dragons-dogma-or-dark-souls-portraying-a-masculine-male-warrior-struggling-to-pull-a.png',
    alt: 'A warrior inspecting their armor, symbolizing identifying weaknesses.',
  },
  [LearningComponents.LearningPath]: {
    imgSrc: 'https://coditioning3.files.wordpress.com/2023/12/dallc2b7e-2023-12-09-22.36.36-a-scene-set-in-a-medieval-shop-that-sells-weapons-and-clothing-inspired-by-the-dragons-dogma-theme.-it-features-a-masculine-warrior-being-measured-b.png',
    alt: 'Custom learning path represented by a warrior preparing in a shop.',
  },
  [LearningComponents.ExploreContent]: {
    imgSrc: 'https://coditioning3.files.wordpress.com/2023/12/dallc2b7e-2023-12-09-22.52.21-1.-an-image-in-a-medieval-fantasy-setting-inspired-by-dragons-dogma-depicting-a-warrior-examining-a-detailed-map-with-a-compass-in-hand.-the-warrio.png',
    alt: 'A warrior examining a map, symbolizing exploring learning materials.',
  },
  [LearningComponents.Coaching]: {
    imgSrc: BackgroundCoachingAndInstructorImg,
    alt: 'A warrior receiving guidance, symbolizing one-to-one coaching.',
  },
  [LearningComponents.InstructorLedCrashCourses]: {
    imgSrc: BackgroundCoachingAndInstructorImg,
    alt: 'A group of learners with an instructor, symbolizing crash courses.',
  },
  [LearningComponents.HumanMockInterviews]: {
    imgSrc: HumanMockInterviewImg,
    alt: 'A warrior preparing for a trial, symbolizing human mock interviews.',
  },
  [LearningComponents.AIMockInterviews]: {
    imgSrc: AIMockInterviewImg,
    alt: 'An AI-driven warrior offering feedback, symbolizing AI-powered mock interviews.',
  },
  [LearningComponents.SideQuests]: {
    imgSrc: '../img/learning_images/side_quests_icon.jpg',
    alt: 'A warrior standing at crossroads, symbolizing optional challenges.',
  },
  [LearningComponents.StudyGroups]: {
    imgSrc: '../img/learning_images/study_groups_icon.jpg',
    alt: 'Scholars gathered around a table, symbolizing collaborative learning.',
  },
  [LearningComponents.Leaderboard]: {
    imgSrc: '../img/learning_images/leaderboard_icon.jpg',
    alt: 'A leaderboard with scores, symbolizing competitive rankings.',
  },
  [LearningComponents.Revision]: {
    imgSrc: '../img/learning_images/revision_icon.jpg',
    alt: 'A scholar revising scrolls, symbolizing review and improvement.',
  },
  [LearningComponents.Achievements]: {
    imgSrc: '../img/learning_images/achievements_icon.jpeg',
    alt: 'A trophy and medals, symbolizing achievements and milestones.',
  },
  [LearningComponents.AlgosAndDataStructuresCrashCourse]: {
    imgSrc: BackgroundAlgorithmFoundationsImg,
    alt: 'Scrolls and diagrams, symbolizing foundations of algorithms.',
  },
  [LearningComponents.AlgosAndDataStructuresCrashCourseIntermediate]: {
    imgSrc: BackgroundAlgorithmIntermediateImg,
    alt: 'Advanced technical diagrams, symbolizing intermediate algorithms.',
  },
  [LearningComponents.SystemDesignCrashCourse]: {
    imgSrc: SystemDesignCrashCourseImg,
    alt: 'Engineers working on a blueprint, symbolizing system design.',
  },
  [LearningComponents.OptimizeYourInterviewPerformanceCrashCourse]: {
    imgSrc: OptimizeYourInterviewPerformanceImg,
    alt: 'A warrior practicing with a mentor, symbolizing interview performance.',
  },
  [LearningComponents.SystemDesignWorkShop]: {
    imgSrc: 'https://coditioning3.wordpress.com/wp-content/uploads/2024/08/systemdesignworkshopthumbanil.webp?w=1024',
    alt: 'A workshop with engineers discussing a blueprint, symbolizing system design workshops.',
  },
  [LearningComponents.CodingAndBehaviouralWorkShop]: {
    imgSrc: 'https://coditioning3.wordpress.com/wp-content/uploads/2024/04/codingworkshopthumbnail.webp?w=1024',
    alt: 'A collaborative coding session, symbolizing coding and behavioral workshops.',
  },
  [LearningComponents.SystemDesignCourse]: {
    imgSrc: 'https://coditioning3.wordpress.com/wp-content/uploads/2024/08/systemdesignworkshopthumbanil.webp?w=1024',
    alt: 'A potion mixer blueprint, symbolizing the intricacies of system design.',
  },
  [LearningComponents.None]: {
    imgSrc: '',
    alt: '',
  },

}


/**
 * Renders learning menu
 * @param {boolean} disabled - If true, render null
 */
const GenericLearningHome: React.FC<LearningHomeProps> = (
  {
    activeTitles,
    disabled = false,
    banner = {
      text: CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG,
      url: WORKSHOPS_PAGE_PATH,
    }

  }
) => {
  const [activeComponent, setActiveComponent] = useState<LearningComponents | null>(LearningComponents.LearningPath);
  const { userId } = useContext(StateAccessor);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialActiveComponentName = queryParams.get("activeComponent");

    if (initialActiveComponentName && Object.values(LearningComponents).includes(initialActiveComponentName as LearningComponents)) {
      setActiveComponent(initialActiveComponentName as LearningComponents);
    } else {

      //if(activeTitles.size > 0) setActiveComponent(activeTitles.values().next().value); // this sets the first value in the set as the active component
      if (!activeTitles.isEmpty()) setActiveComponent(activeTitles.getFirstActiveComponent()!);
    }
  }, [location.search]); // useEffect called when the URL search parameters change


  const handleCardClick = (title: LearningComponents) => {
    setActiveComponent(title);
  };

  // Add components when other learning cards are clicked as needed
  const renderActiveComponent = () => {
    logToBackendLogFile(
      `Viewed the ${activeComponent} component from the learning menu`,
      "info",
      userId);

    if (activeComponent === LearningComponents.ExploreContent) {
      return <CoursesMenu progress={new Progress(0, 1)} />;
    } else if (activeComponent != null) {
      return <MemoizedLearningHomeSubMenu mode={activeComponent} />;
    } else {
      return <MemoizedLearningHomeSubMenu mode={LearningComponents.None} />;
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <div className='learning-home'>
      <div className='learning-menu'>
        <div className="learning-cards">
          {Object.entries(LearningModeCoverArt)
            .filter(([key]) => activeTitles.has(key as LearningComponents))
            .map(([key, value]) => ({
              title: key as LearningComponents,
              ...value,
              orderRank: activeTitles.getRankByLearningComponent(key as LearningComponents),
            }))
            .sort((card1, card2) => card1.orderRank - card2.orderRank!)
            .map(({ title, imgSrc, alt }) => (
              <div key={title} className={`learning-card-container`} title={title}>
                <h6 className='learning-card-title'>{title}</h6>
                <div
                  className={`learning-card ${title === activeComponent ? 'active-learning-card' : ''}`}
                  onClick={() => handleCardClick(title)}
                >
                  <img src={imgSrc} alt={alt} loading='lazy' />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className='active-component-container'>
        {renderActiveComponent()}
      </div>
    </div>
  );
};
export default GenericLearningHome;