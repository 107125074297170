import React from "react";
import "../css/MediaIframe.css";

interface MediaIframeProps {
  mediaUrl: string;
  title?: string;
}

const MediaIframe: React.FC<MediaIframeProps> = ({ mediaUrl, title }) => {
  return (
    <div className="media-iframe-container">
      {/* Optional navbar or white space */}
      <div className="media-iframe-navbar"></div>

      {/* Flexible media iframe */}
      <iframe
        src={mediaUrl}
        width="100%"
        height="100%"
        title={title || "Embedded Media"}
        className="media-iframe"
      ></iframe>
    </div>
  );
};

export default MediaIframe;