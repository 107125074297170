import React from 'react';
import { Link } from 'react-router-dom'; 
import { WORKSHOPS_PAGE_PATH, HUMAN_INTERVIEWER_COACH_PATH } from '../utils/Constants';
import '../css/CoursesSidebar.css'; 

// Define an interface for the sections array
interface Section {
  id: string;
  title: string;
  description: string;
  link: string;
  buttonText: string;
}

const CoursesSidebar: React.FC = () => {
  const sections: Section[] = [
    {
      id: 'mock-interviews',
      title: 'Avoid Surprises with our Mock Interview Service',
      description: `Assess your interview readiness objectively, and uncover any weaknesses before, not during the actual interview. Try our tailored mock interviews which help you prepare under realistic conditions`,
      link: HUMAN_INTERVIEWER_COACH_PATH,
      buttonText: 'Learn More'
    },
    {
      id: 'workshop',
      title: 'Interview Prep and Chill?',
      description: `Join our next interview prep workshop. It's super casual, ask any questions, work on a real interview problem, get feedback, and hang out with others on the interview prep journey.`,
      link: WORKSHOPS_PAGE_PATH,
      buttonText: 'Register'
    }
  ];

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-container">
        {sections.map((section) => (
          <div key={section.id} className="sidebar-section">
            <h6>{section.title}</h6>
            <p>{section.description}</p>
            <div className="section-link">
              <Link to={section.link} className="sidebar-button" aria-label={`Learn more about ${section.title}`}>
                {section.buttonText}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesSidebar;
