// Careers.tsx

import React from 'react';
import JobPosting, { JobPostingData } from './JobPosting';
import '../../css/Careers.css';

const SOFTWARE_ENGINEER_INTERN_QUESTIONNAIRE_URL = 'https://forms.gle/aQy7rTYDZT6sTwVi7';

const OUR_CULTURE_SECTION = `
<h3>Our Culture:</h3>
<ul>
  <li><strong>Flexible Work Hours:</strong>
    <ul>
      <li>Choose your own hours as long as you work on at least 3 days (ideally spaced out) during the week, and deliver.</li>
    </ul>
  </li>
  <li><strong>Relaxed and Collaborative Environment:</strong>
    <ul>
      <li>No micromanaging; we trust you to get the job done.</li>
      <li>Non-toxic, supportive team that values collaboration and making an impact.</li>
      <li>Obsessed with efficiency, a prefer to work smarter than harder culture, if you value automation & efficiency you will fit right in.</li>
    </ul>
  </li>
</ul>
`;

const HOW_TO_APPLY_SECTION = (questionnaireUrl?: string) => `
<h3>How to Apply:</h3>
<ul>
  ${questionnaireUrl ? `
  <li>
    <a class="questionnaire" href="${questionnaireUrl}" target="_blank" rel="noopener noreferrer">
      Tell us about your interests by filling this 5-minute questionnaire
    </a>
  </li>` : ''}
  <li>Send us your CV, and feel free to share any portfolio or relevant projects you've worked on.</li>
</ul>
`;

const generateJobDescription = (jobSpecificContent: string, questionnaireUrl?: string) => `
  ${jobSpecificContent}
  ${OUR_CULTURE_SECTION}
  ${HOW_TO_APPLY_SECTION(questionnaireUrl)}
`;

const Careers: React.FC = () => {
  const jobPostings: JobPostingData[] = [
    // 1. Growth Software Engineer Intern
    {
      id: 1,
      title: 'Growth Software Engineer Intern (Remote)',
      isHiring: true,
      description: generateJobDescription(`
        <p>Coditioning is seeking a <strong>Growth Software Engineer Intern</strong> who is excited about the intersection of software engineering and growth optimization. This dual role involves engaging in growth optimization tasks and contributing to front-end development projects. We value your ability to learn and adapt over prior experience.</p>
        
        <h3>Responsibilities:</h3>
        <ul>
          <li><strong>Growth Optimization:</strong>
            <ul>
              <li>Set up and manage tools like Google Search Console and Analytics to track performance and optimize traffic and conversions.</li>
              <li>Run and evaluate paid ad campaigns to drive immediate traffic and gain insights on keyword performance.</li>
              <li>Optimize the website for SEO based on research and campaign data.</li>
              <li>Collaborate on A/B testing and conversion rate optimization initiatives.</li>
              <li>Drive organic traffic and improve search rankings.</li>
            </ul>
          </li>
          <li><strong>Software Development:</strong>
            <ul>
              <li>Work on front-end development tasks, implementing new features and improving the user experience.</li>
              <li>Optimize the front-end codebase with performance in mind, including potential A/B tests for feature optimizations.</li>
              <li>Be open to back-end development tasks (optional).</li>
            </ul>
          </li>
          <li><strong>Leverage AI and Automation:</strong>
            <ul>
              <li>Use AI tools and automation to enhance productivity and efficiency.</li>
              <li>Explore innovative technologies to drive growth and development efforts.</li>
            </ul>
          </li>
          <li><strong>Continuous Learning:</strong>
            <ul>
              <li>Stay updated with the latest tools and technologies.</li>
              <li>Be eager to learn and adapt to new methodologies.</li>
            </ul>
          </li>
        </ul>
        
        <h3>Requirements:</h3>
        <ul>
          <li><strong>Passion for Efficiency:</strong>
            <ul>
              <li>Keen interest in using AI, automation, scripting, and other tools to enhance productivity.</li>
              <li>Enthusiasm for working efficiently and effectively.</li>
            </ul>
          </li>
          <li><strong>Technical Skills:</strong>
            <ul>
              <li>Experience with React, TypeScript, HTML, and CSS.</li>
              <li>Basic understanding of Python is a bonus.</li>
            </ul>
          </li>
          <li><strong>Learning Aptitude:</strong>
            <ul>
              <li>Ability to learn quickly and make rational decisions.</li>
              <li>We value your ability to learn over existing knowledge.</li>
            </ul>
          </li>
          <li><strong>Growth Mindset:</strong>
            <ul>
              <li>Keen interest in growth marketing and optimization.</li>
              <li>Familiarity with SEO, analytics, and A/B testing concepts is a bonus but not required.</li>
            </ul>
          </li>
          <li><strong>Communication Skills:</strong>
            <ul>
              <li>Strong command of the English language.</li>
              <li>Ability to communicate effectively verbally and in writing.</li>
            </ul>
          </li>
          <li><strong>Commitment:</strong>
            <ul>
              <li>Ability to commit at least 10 hours a week.</li>
              <li>Takes pride in the quality of work and has strong attention to detail.</li>
            </ul>
          </li>
        </ul>
      `, SOFTWARE_ENGINEER_INTERN_QUESTIONNAIRE_URL),
    },

    // 2. Technical Writer and AI Prompt Engineer Intern
    {
      id: 2,
      title: 'AI Prompt Engineer Intern (Remote)',
      isHiring: true,
      description: generateJobDescription(`
        <p>We are seeking a <strong>Technical Writer and AI Prompt Engineer Intern</strong> who is passionate about teaching. In this role, you will create engaging tutorials, challenges, and learning materials on computer science topics, primarily data structures and algorithms. You will utilize AI tools, including Large Language Models (LLMs) like ChatGPT and Claude, to enhance content creation, ensuring materials are clear, accurate, and easy to digest.</p>
    
        <h3>Responsibilities:</h3>
        <ul>
          <li><strong>Develop Educational Content:</strong>
            <ul>
              <li>Create high-quality tutorials, challenges, and learning resources in areas like data structures and algorithms.</li>
              <li>Design content that reinforces learning and aids in knowledge retention, incorporating principles like spaced repetition.</li>
            </ul>
          </li>
          <li><strong>Leverage AI Tools:</strong>
            <ul>
              <li>Utilize AI technologies, such as LLMs, to generate and refine content.</li>
              <li>Use AI tools to drive productivity and efficiency in content creation.</li>
              <li>Guide AI outputs to ensure accuracy, clarity, and educational value.</li>
            </ul>
          </li>
          <li><strong>Ensure Clarity and Accuracy:</strong>
            <ul>
              <li>Explain complex concepts in an easy-to-understand manner.</li>
              <li>Eliminate knowledge gaps by thoroughly covering topics and explaining jargon.</li>
            </ul>
          </li>
          <li><strong>Continuous Learning:</strong>
            <ul>
              <li>Stay updated with the latest AI tools and educational trends.</li>
              <li>Be eager to learn and adapt to new technologies and methodologies.</li>
            </ul>
          </li>
        </ul>
    
        <h3>Requirements:</h3>
        <ul>
          <li><strong>Passion for Efficiency:</strong>
            <ul>
              <li>Keen interest in using AI, automation, scripting, and other tools to enhance productivity.</li>
              <li>Enthusiasm for working efficiently and effectively.</li>
            </ul>
          </li>
          <li><strong>Strong Communication Skills:</strong>
            <ul>
              <li>Excellent command of the English language, both written and verbal.</li>
              <li>Ability to explain technical concepts clearly and concisely.</li>
              <li>Theory of mind: ability to anticipate what the reader might not understand and factor this into how you explain things.</li>
            </ul>
          </li>
          <li><strong>Learning Aptitude:</strong>
            <ul>
              <li>Eager to learn and adopt new tools and technologies.</li>
              <li>Ability to learn quickly and make rational decisions.</li>
              <li>We value your ability to learn over existing knowledge.</li>
            </ul>
          </li>
          <li><strong>Technical Knowledge:</strong>
            <ul>
              <li>Familiarity with computer science fundamentals, including data structures and algorithms.</li>
              <li>Experience with AI tools like ChatGPT and Claude is a bonus but not required.</li>
            </ul>
          </li>
        </ul>
      `),
    },
    

    // 3. Product Growth Strategist Intern
    {
      id: 3,
      title: 'Product Growth Strategist Intern (Remote)',
      isHiring: true,
      description: generateJobDescription(`
        <p>We are looking for a <strong>Product Growth Strategist Intern</strong> to help accelerate our user growth and enhance product engagement. This role focuses on developing and executing strategies to increase traffic, improve conversion rates, and drive product development initiatives. <strong>This role is well suited to individuals that are both analytical and creative, being able to think unconventionally and spot things others typically miss is a big plus.</strong></p>
    
        <h3>Responsibilities:</h3>
        <ul>
          <li><strong>Develop Growth Strategies:</strong>
            <ul>
              <li>Identify opportunities to increase user acquisition and retention.</li>
              <li>Create and implement plans to boost site traffic and user engagement.</li>
            </ul>
          </li>
          <li><strong>Optimize Conversion Rates:</strong>
            <ul>
              <li>Analyze user behavior and funnel metrics to improve conversion.</li>
              <li>A/B test different strategies to determine the most effective approaches.</li>
            </ul>
          </li>
          <li><strong>Product Development Collaboration:</strong>
            <ul>
              <li>Collaborate with the product team to suggest enhancements based on user feedback.</li>
              <li>Assist in prioritizing product features that align with growth objectives.</li>
            </ul>
          </li>
          <li><strong>Leverage AI and Automation:</strong>
            <ul>
              <li>Utilize AI tools and automation to enhance efficiency in growth initiatives.</li>
              <li>Explore innovative technologies to drive productivity.</li>
            </ul>
          </li>
          <li><strong>Continuous Learning:</strong>
            <ul>
              <li>Stay informed about industry trends and competitor strategies.</li>
              <li>Be eager to learn and adapt to new tools and methodologies.</li>
            </ul>
          </li>
        </ul>
    
        <h3>Requirements:</h3>
        <ul>
          <li><strong>Passion for Efficiency:</strong>
            <ul>
              <li>Keen interest in using AI, automation, and scripting to enhance productivity.</li>
              <li>Enthusiasm for working efficiently and effectively.</li>
            </ul>
          </li>
          <li><strong>Analytical Skills:</strong>
            <ul>
              <li>Strong ability to interpret data and translate insights into action plans.</li>
              <li>Familiarity with analytics tools (e.g., Google Analytics) is a bonus but not required.</li>
            </ul>
          </li>
          <li><strong>Learning Aptitude:</strong>
            <ul>
              <li>Ability to learn quickly and make rational decisions.</li>
              <li>We care more about your ability to learn than your existing knowledge.</li>
            </ul>
          </li>
          <li><strong>Strategic Thinking:</strong>
            <ul>
              <li>Creative thinking in addressing growth challenges.</li>
              <li>Proactive in proposing innovative solutions.</li>
            </ul>
          </li>
          <li><strong>Communication Skills:</strong>
            <ul>
              <li>Excellent written and verbal communication.</li>
              <li>Ability to collaborate effectively with cross-functional teams.</li>
            </ul>
          </li>
        </ul>
      `),
    },

    // 4. Full Stack Software Engineer Intern
    {
      id: 4,
      title: 'Full Stack Software Engineer Intern (Remote)',
      isHiring: true,
      description: generateJobDescription(`
        <p>We are seeking a <strong>Full Stack Software Engineer Intern</strong> proficient in both front-end and back-end development. In this role, you will contribute to building and enhancing our platform using technologies like React, TypeScript, Python, and PostgreSQL. We prioritize your ability to learn and adapt over prior experience.</p>
    
        <h3>Responsibilities:</h3>
        <ul>
          <li><strong>Front-End Development:</strong>
            <ul>
              <li>Develop and maintain user interfaces using React and TypeScript.</li>
              <li>Ensure cross-platform optimization and responsiveness of applications.</li>
              <li>Improve user experience through UI/UX enhancements.</li>
            </ul>
          </li>
          <li><strong>Back-End Development:</strong>
            <ul>
              <li>Build and optimize server-side logic using Python.</li>
              <li>Develop and manage databases with PostgreSQL.</li>
              <li>Create and maintain APIs for seamless front-end and back-end integration.</li>
            </ul>
          </li>
          <li><strong>Leverage AI and Automation:</strong>
            <ul>
              <li>Utilize AI tools and automation to enhance development efficiency.</li>
              <li>Explore innovative technologies to improve the codebase and performance.</li>
            </ul>
          </li>
          <li><strong>Collaboration and Best Practices:</strong>
            <ul>
              <li>Work closely with cross-functional teams to define and implement new features.</li>
              <li>Participate in code reviews and adhere to coding standards.</li>
              <li>Troubleshoot and debug issues across the stack.</li>
            </ul>
          </li>
          <li><strong>Continuous Learning:</strong>
            <ul>
              <li>Stay updated with emerging technologies and industry trends.</li>
              <li>Be eager to learn and adopt new tools and methodologies.</li>
            </ul>
          </li>
        </ul>
    
        <h3>Requirements:</h3>
        <ul>
          <li><strong>Passion for Efficiency:</strong>
            <ul>
              <li>Keen interest in using AI, automation, scripting, and other tools to enhance productivity.</li>
              <li>Enthusiasm for working efficiently and effectively.</li>
            </ul>
          </li>
          <li><strong>Technical Proficiency:</strong>
            <ul>
              <li>Experience with React, TypeScript, HTML, and CSS for front-end development.</li>
              <li>Proficiency in Python and familiarity with PostgreSQL for back-end development.</li>
            </ul>
          </li>
          <li><strong>Learning Aptitude:</strong>
            <ul>
              <li>Ability to learn quickly and make rational decisions.</li>
              <li>We value your ability to learn over existing knowledge.</li>
            </ul>
          </li>
          <li><strong>Problem-Solving Skills:</strong>
            <ul>
              <li>Strong analytical abilities to identify and resolve technical issues.</li>
              <li>Attention to detail and commitment to code quality.</li>
            </ul>
          </li>
          <li><strong>Communication Skills:</strong>
            <ul>
              <li>Ability to effectively communicate technical concepts.</li>
              <li>Collaborative mindset and willingness to work in a team environment.</li>
            </ul>
          </li>
        </ul>
      `, SOFTWARE_ENGINEER_INTERN_QUESTIONNAIRE_URL),
    },
  ];

  return (
    <div className="careers-container">
      <h1>We have {jobPostings.filter((job) => job.isHiring).length} open position(s)</h1>
      <div className="careers-grid">
        {jobPostings.map((job) => (
          <JobPosting key={job.id} job={job} />
        ))}
      </div>
    </div>
  );
};

export default Careers;