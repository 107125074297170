import { GripVertical } from "lucide-react";
import * as ResizablePrimitive from "react-resizable-panels";
import { cn } from "@/lib/utils";

// Define orientation type as a union type for better type safety
type Orientation = "horizontal" | "vertical";

// Resizable Panel Group Component
const ResizablePanelGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => (
  <ResizablePrimitive.PanelGroup
    className={cn(
      "flex h-full w-full data-[panel-group-direction=vertical]:flex-col",
      className
    )}
    {...props}
  />
);

// Resizable Panel Component
const ResizablePanel = ResizablePrimitive.Panel;

// Resizable Handle Component with improved readability and type safety
const ResizableHandle = ({
  withHandle,
  className,
  orientation,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
  orientation: Orientation;
}) => {
  // Classes for different orientations
  const verticalClasses = "data-[panel-group-direction=vertical]:h-2 data-[panel-group-direction=vertical]:w-full data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90 ";
  
  const baseClasses = "relative flex w-px items-center justify-center after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 bg-indigo-400 opacity-50 hover:opacity-100 ";

  // Handle container class
  const handleClasses = cn(
    baseClasses,
    verticalClasses,
    className,
    orientation === "horizontal" ? "h-[20em] my" : "w-2 mx"
  );

  // Grip size classes for inner div
  const gripSize = "h-2.5 w-2.5";

  return (
    <ResizablePrimitive.PanelResizeHandle className={handleClasses} {...props}>
      {withHandle && (
        <div className="z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-border">
          <GripVertical className={gripSize} />
        </div>
      )}
    </ResizablePrimitive.PanelResizeHandle>
  );
};

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };