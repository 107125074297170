import React, { useState } from 'react';
import '../../css/Careers.css';
import { Link } from 'react-router-dom';

import { CAREERS_CONTACT_EMAIL } from '@/utils/Constants';

export interface JobPostingData {
  id: number;
  title: string;
  isHiring: boolean;
  description: string;
}

interface JobPostingProps {
  job: JobPostingData;
}

const JobPosting: React.FC<JobPostingProps> = ({ job }) => {
  const [hasConsented, setHasConsented] = useState(false);

  const handleApplyClick = () => {
    if (hasConsented) {
      const emailTitle = !job.isHiring
        ? `Submitting Application for future internship opportunities`
        : `Applying for ${job.title}`;
      window.open(`mailto:${CAREERS_CONTACT_EMAIL}?subject=${encodeURIComponent(emailTitle)}`);
    } else {
      alert('Please consent to the privacy policy before applying');
    }
  };

  return (
    <div className="careers">
      {!job.isHiring && (
        <div className="careers__hiringStatus">
          Hiring is currently paused for this opportunity, but you can apply for future openings
        </div>
      )}
      <h2 className="careers__title">{job.title}</h2>
      <div
        className="careers__description"
        dangerouslySetInnerHTML={{ __html: job.description }}
      />
      {job.isHiring ? (
        <button className="careers__applyButton" onClick={handleApplyClick}>
          Apply via Email
        </button>
      ) : (
        <>
          <p className="careers__status">
            Hiring is currently paused for this opportunity. You can still submit your application for future
            consideration.
          </p>
          <button className="careers__applyButton" onClick={handleApplyClick}>
            Submit for future consideration
          </button>
        </>
      )}

      <div className="careers__consent">
        <input
          type="checkbox"
          id={`consent-${job.id}`}
          name={`consent-${job.id}`}
          onChange={() => setHasConsented(!hasConsented)}
        />
        <label htmlFor={`consent-${job.id}`}>
          {' '}
          I have read and agree to the{' '}
          <Link to="/privacy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </Link>
          .
        </label>
      </div>

      <i> all application emails should be sent to {CAREERS_CONTACT_EMAIL}</i>
    </div>
  );
};

export default React.memo(JobPosting);