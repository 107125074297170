import React, { useCallback, useContext, useEffect, useState } from 'react';
import '../css/CoursesMenu.css';
import Progress from '../utils/Progress';
import { useNavigate } from 'react-router-dom';
import { getUserCourseProgress } from '../externalLayerAccessor/BackEndRequests';
import { UserProgress, ProgressStatus, MinifiedCourse } from '../utils/Constants';
import Ribbon from '../utils/Ribbon';
import StateAccessor from '../StateAccessor';
import Spinner from '@/utils/Spinner';
import CourseFetcherService from '@/utils/CourseFetcherService';

interface CoursesMenuProps {
  disabled?: boolean;
  progress: Progress;
}

/**
 * Component to display the courses menu
 * @param {CoursesMenuProps} props - props passed to the component
 */
const CoursesMenu: React.FC<CoursesMenuProps> = ({ progress, disabled = false }) => {
  const navigate = useNavigate();
  const { userId } = useContext(StateAccessor);

  // State to store fetched minified courses
  const [courses, setCourses] = useState<MinifiedCourse[] | null>(null);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(true);

  // State to store course progress
  const [courseProgressMap, setCourseProgressMap] = useState<Map<number, UserProgress[]>>(new Map());

  // Fetch minified courses on mount
  useEffect(() => {
    async function fetchMinifiedCourses() {
      try {
        setLoadingCourses(true);
        const fetchedCourses = await CourseFetcherService.fetchMinifiedCourses();
        setCourses(fetchedCourses);
      } catch (error) {
        console.error("Error fetching minified courses:", error);
        setCourses(null); // Handle failure gracefully
      } finally {
        setLoadingCourses(false);
      }
    }

    fetchMinifiedCourses();
  }, []);

  // Fetch progress for courses when courses are available
  useEffect(() => {
    async function fetchAllCourseProgress() {
      if (courses && courses.length > 0) {
        const progressMap = new Map<number, UserProgress[]>();
        await Promise.all(
          courses.map(async (course) => {
            const courseProgress = await getUserCourseProgress(userId, course.course_id);
            if (courseProgress !== null) {
              progressMap.set(course.course_id, courseProgress);
            }
          })
        );
        setCourseProgressMap(progressMap);
      }
    }

    fetchAllCourseProgress();
  }, [userId, courses]);

  // Handles navigation to a course on click
  const handleClick = useCallback(
    (courseId: number) => {
      navigate(`./courses/${courseId}`);
    },
    [navigate]
  );

  // If component is disabled, return null
  if (disabled) {
    return null;
  }

  // Render the spinner while courses are being fetched
  if (loadingCourses) {
    return <Spinner />;
  }

  return (
    <div className="courses-menu">
      <div className="menu-cards-container">
        {courses && courses.length > 0 ? (
          courses.map((course) => (
            <div
              key={course.course_id}
              className="course-card"
              onClick={() => handleClick(course.course_id)}
            >
              <div className="course-card-bg">
                <img
                  src={course.thumbnail_url}
                  alt={`Thumbnail for ${course.title}`}
                  loading="lazy"
                  className="course-card-img"
                />
              </div>
              <h5 className="course-card-title">{course.title}</h5>
              <div className="course-progress">
                {courseProgressMap.get(course.course_id)?.map((progress, index) => (
                  <div key={index}>
                    <Ribbon
                      parameter={progress.status}
                      data={{
                        [ProgressStatus.InProgress]: { color: 'yellow', text: 'In Progress' },
                        [ProgressStatus.Completed]: { color: 'green', text: 'Completed' },
                        [ProgressStatus.NotStarted]: { color: 'red', text: 'Not Started' },
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="no-courses">No courses available.</div>
        )}
      </div>
    </div>
  );
};

export default CoursesMenu;