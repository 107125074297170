import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AssessmentContext } from '../utils/AssessmentContext';
import '../css/LearningHomeSubMenu.css';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import BrainIcon from '@mui/icons-material/Psychology';
import GamepadIcon from '@mui/icons-material/Gamepad';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TargetIcon from '@mui/icons-material/Adjust';
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BugReportIcon from '@mui/icons-material/BugReport';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MapIcon from '@mui/icons-material/Map';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { AI_INTERVIEW_SELECTION_PAGE_PATH, CODING_AND_BEHAVIOURAL_WORKSHOP_URL, CRASH_COURSE_PAGE_PATH, HUMAN_INTERVIEWER_COACH_PATH, LEARNING_PAGE_COURSES_SUB_PATH, LEARNING_PAGE_PATH, LEARNING_PATH_GENERATOR_PATH, LEARNING_PATH_PATH, LOCAL_STORAGE_LEARNING_PATH, SYSTEM_DESIGN_COURSE_ID, SYSTEM_DESIGN_WORKSHOP_URL } from '../utils/Constants';
import Modal from '../utils/Modal';
import { LearningComponents } from './GenericLearningHome';
import { openUrl, openUrlInNewTab } from '../utils/HelperFunctions';
import TargetTechEmployersIcon from './TargetTechEmployersIcon';



const REGISTRATION_FORM_FOR_CRASH_COURSES_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSe5sMwu2l4dOqomzJn53TtpQm5YVj5Ut80BMXLwKzBnybxAvQ/viewform?usp=sf_link';
const COACHING_SESSION_URL = HUMAN_INTERVIEWER_COACH_PATH;

const CrashCourseCommonFeatureList: React.FC = () => {

  return (
    <div className='assessments-top-row'>
      <ul className="feature-list">
        <li>
          <AccessTimeIcon />&nbsp;Save Time & Effort: We've curated everything you need, and will guide you every step along the way.
        </li>

        <li>
          <BrainIcon />&nbsp;Optimized Learning with Neuroscience: Designed to help you retain what you learn, and enable application of deep understanding to unfamiliar problems.
        </li>

        <li>
          <TargetIcon />&nbsp;Interview Focused: We'll equip you with the knowledge and techniques you need to ace your SWE Interview at FAANG and other top tech employers.
        </li>

        <li>
          <PersonIcon />&nbsp; Instructor-Led Learning: Benefit from the guidance of experienced instructors who not only have extensive industry experience, but love & can actually teach.
        </li>

      </ul>
    </div>
  )

}

const TechCompanyLogos: React.FC = () => {
  return (
    <TargetTechEmployersIcon
      width="70%"
      minWidth='300px'
    />
  )
}

const CustomButton: React.FC<{
  onClick: () => void,
  label: string,
  bgColor?: string,

}> = ({ onClick, label, bgColor = "#e67321" }) => {

  return (
    <Box className='learning-home-sub-menu-action-container'>
      <Button
        variant="contained"
        color="primary"
        className="learning-path-button"
        onClick={onClick}
        sx={{
          marginTop: "5px",
          fontSize: "23px",
          padding: "10px 20px",
          borderRadius: "20px",
          display: "block",
          backgroundColor: bgColor
        }}
        disabled={false}
      >
        {label}
      </Button>
    </Box>

  )


}


const CrashCourseDetails: React.FC<{ detailsHtml: string }> = ({ detailsHtml }) => {
  return (
    <div className='course-content' dangerouslySetInnerHTML={{ __html: detailsHtml }} />
  )
}

const CrashCourseSyllabus: React.FC<{ syllabusHtml: string }> = ({ syllabusHtml }) => {
  return (
    <div className='course-syllabus' dangerouslySetInnerHTML={{ __html: syllabusHtml }} />
  )
}


interface LearningHomeSubMenuProps {
  mode: LearningComponents;
  disabled?: boolean;
}

const LearningHomeSubMenu: React.FC<LearningHomeSubMenuProps> = ({ disabled = false, mode }) => {
  const [hasLearningPath, setHasLearningPath] = useState(false);
  const [showAlgosAndDatastructuresCrashCourseSyllabusModal, setShowAlgosAndDatastructuresCrashCourseSyllabusModal] = useState(false);
  const [showAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal, setShowAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal] = useState(false);
  const [showOptimizeYourInterviewPerformanceCrashCourseSyllabusModal, setShowOptimizeYourInterviewPerformanceCrashCourseSyllabusModal] = useState(false);
  const [showSystemDesignCrashCourseSyllabusModal, setShowSystemDesignCrashCourseSyllabusModal] = useState(false);

  if (disabled) {
    return null;
  }

  let { assessments } = useContext(AssessmentContext);
  if (!assessments) {
    console.error("Assessments not loaded, defaulting to empty array");
    assessments = [];
  }
  const navigate = useNavigate();

  const getLearningPathFromDataLayer = () => {
    // For MVP: Fetching from browser cache
    return localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH);
  };

  const navigateToAssessment = (assessment_id: number) => {
    if (assessments === null) {
      console.error("Assessments not loaded");
      return;
    }

    if (assessment_id < 0 || assessment_id >= assessments.length) {
      console.error("Invalid assessment selected")
      return;
    }
    navigate(`/app/learning/assessments/${assessment_id}`);
  };

  const viewLearningPath = () => {
    navigate(LEARNING_PATH_PATH);
  }

  const navigateToLearningPathGenerator = () => {
    navigate(LEARNING_PATH_GENERATOR_PATH);
  }

  const navigateToCoachingSessionBookingPage = () => {
    openUrl(COACHING_SESSION_URL);
  }

  const navigateToHumanInterviewerCoachPage = () => {
    openUrl(HUMAN_INTERVIEWER_COACH_PATH);
  }

  const navigateToAIMockInterviewsPage = () => {
    navigate(AI_INTERVIEW_SELECTION_PAGE_PATH);
  }

  const renderLearningPath = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <PersonPinIcon /> Personalized Learning: One size does not fit all. Dive into a tailored learning experience for you.
            </li>
            <li>
              <BrainIcon /> Brain-Optimized Challenges: Study smart, not hard. Our learning path leverages spaced repetition and neuroscience insights to optimize your learning.
            </li>
            <li>
              <GamepadIcon /> Gamified Mastery: Not suited for masochists. For those who want a more fun and effective alternative to grinding, learn through gamified challenges.
            </li>
          </ul>
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          {hasLearningPath ? (
            <Button
              variant="contained"
              color="primary"
              className="learning-path-button"
              onClick={viewLearningPath}
              sx={{
                marginTop: "5px",
                fontSize: "23px",
                padding: "10px 20px",
                borderRadius: "20px",
                display: "block",
              }}
            >
              View Learning Path
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="learning-path-button"
              onClick={navigateToLearningPathGenerator}
              sx={{
                marginTop: "5px",
                fontSize: "23px",
                padding: "10px 20px",
                borderRadius: "20px",
                display: "block",
              }}
              disabled={false}
            >
              Generate Your Custom Learning Path
            </Button>
          )}
        </Box>
      </>

    )
  }

  const renderDiscoverWeaknesses = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <HelpOutlineIcon /> &nbsp;Uncover your areas of weakness in data structures and algorithms, as well as system design. We help you identify your knowledge gaps so you can prioritize your learning.
            </li>
          </ul>
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          {assessments && (
            <div className="assessments-list">
              {assessments.map((assessment, index) => (
                <Button
                  variant="contained"
                  color="primary"
                  className="assessment-button"
                  onClick={() => navigateToAssessment(assessment.assessment_id)}
                  sx={{
                    fontSize: "18px",
                    padding: "15px 30px",
                    borderRadius: "20px",
                    backgroundColor: index % 2 === 0 ? "#703733" : "#2c3e50",
                    display: "block",
                    marginBottom: "5px",
                    textTransform: "none"
                  }}
                >
                  <AssessmentIcon style={{ marginRight: '0.5rem' }} />
                  {assessment.title}
                </Button>
              ))}
            </div>
          )}
        </Box>
      </>
    )
  }

  const renderInterviewReadinessAssessment = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <PersonPinIcon /> &nbsp;Take a mock interview and find out how interview-ready you are. We provide feedback on strengths and areas for improvement.
            </li>

            <li>
              <HelpOutlineIcon /> &nbsp; Get guidance on how to improve your interview performance, all tailored to your target companies.
            </li>

            <li>
              <BrainIcon /> &nbsp; Learn how to get better at handling unfamiliar problems, optimizing your solutions, writing bug-free code quickly, and more.
            </li>
          </ul>
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          <CustomButton
            onClick={navigateToHumanInterviewerCoachPage}
            label='Book Diagnostic Mock Interview'
            bgColor='#703733'

          />
        </Box>
      </>
    )
  }

  const renderCoachingFeature = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>✓ &nbsp;Get truly interview-ready with our comprehensive coaching, designed to identify your weaknesses in real-time and provide actionable feedback.</li>
            <li>✓ &nbsp;Skip the trial-and-error, learn the proven strategies employed by experienced interviewers from the get-go.</li>
            <li>✓ &nbsp;Get tailored advice addressing your unique challenges from brain-freezes to techniques for solving unfamiliar problems and more.</li>

          </ul>
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          <Button
            variant="contained"
            color="primary"
            className="learning-component-button"
            onClick={navigateToCoachingSessionBookingPage}
            sx={{
              marginTop: "5px",
              fontSize: "23px",
              padding: "10px 20px",
              borderRadius: "20px",
              display: "block",
            }}
          >
            Dedicated Coaching Service
          </Button>

        </Box>
      </>

    )
  }

  const renderCrashCourses = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>✓ &nbsp;Accelerate your journey towards interview-readiness</li>
            <li>✓ &nbsp;Optimized for learning and retention</li>
            <li>✓ &nbsp;Ideal for those pressed for time or that prefer handholding during their preparation</li>
          </ul>
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          <CustomButton
            onClick={() => openUrl(CRASH_COURSE_PAGE_PATH)}
            label='View Crash Courses'
            bgColor='#703733'
          />

        </Box>
      </>

    )
  }

  const renderHumanMockInterviews = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>✓ &nbsp;Practice under realistic interview conditions with an experienced senior software engineer interviewer.</li>
            <li>✓ &nbsp;Practice problem-solving whilst engaging with an interviewer, explaining your code/decisions, time-management etc.</li>
            <li>✓ &nbsp;Questions and interview conditions tailored to your target company.</li>
            <li>✓ &nbsp;Coding, Low-Level Design, System Design and Behavioural interviews available.</li>
            <li>✓ &nbsp;Get actionable feedback on your performance (including a hire or no-hire decision), and guidance on how to improve.</li>
          </ul>

          <TechCompanyLogos />
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          <Button
            variant="contained"
            color="primary"
            className="learning-component-button"
            onClick={navigateToHumanInterviewerCoachPage}
            sx={{
              marginTop: "5px",
              fontSize: "23px",
              padding: "10px 20px",
              borderRadius: "20px",
              display: "block",
              textTransform: "none"
            }}
          >
            Explore Human-in-the-loop Mock Interviews
          </Button>
        </Box>
      </>

    )
  }

  const renderAIMockInterviews = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>✓ &nbsp;Interviews tailored to the questions and conditions of specific companies like Meta, Amazon, Google, etc.</li>
            <li>✓ &nbsp;Practice on-demand with our AI interviewer. Whenever you're ready, our AI interviewer is ready.</li>
            <li>✓ &nbsp;Questions tailored to interview stages such as phone screens, onsites, etc.</li>
            <li>✓ &nbsp;Both coding and low-level design interviews are available.</li>
          </ul>

          <TechCompanyLogos />
        </div>
        <Box className='learning-home-sub-menu-action-container'>
          <Button
            variant="contained"
            color="primary"
            className=".learning-component-button"
            onClick={navigateToAIMockInterviewsPage}
            sx={{
              marginTop: "5px",
              fontSize: "23px",
              padding: "10px 20px",
              borderRadius: "20px",
              display: "block",
              textTransform: "none"
            }}
            disabled={false}
          >
            <div>
              Explore AI Mock Interviews
              <span className="beta-label">BETA</span>
            </div>
          </Button>

        </Box>
      </>

    )
  }

  const renderAlgosAndDataStructuresCrashCourse = () => {
    const detailsHtml: string = `
        <p>This interview-focused crash course will give you a solid foundation in Algorithms and Data Structures, focusing on helping you master the fundamental data structures, knowing when to use them
        and how to use them when solving the type of algorithmic problems you'll encounter in your interviews and in your day-to-day work as a software engineer. <strong>Suitable for beginners or those out of practice looking to refresh their knowledge.</strong>
        </p>
        <p>
        <ul>
          <li>Number of Sessions: 12</li>
          <li>Session length: 40 minutes - 1 hour</li>
        </ul>
        </p>
        `;
    return (
      <>
        <CrashCourseDetails detailsHtml={detailsHtml} />
        <CrashCourseCommonFeatureList />
        <CustomButton onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} label='Register interest' />
        <CustomButton
          label='View Course Content'
          onClick={() => setShowAlgosAndDatastructuresCrashCourseSyllabusModal(true)}
          bgColor='#2c3e50'
        />
        {showAlgosAndDatastructuresCrashCourseSyllabusModal && (
          <Modal
            content={
              <>
                <CrashCourseSyllabus

                  syllabusHtml={`
                                <h2>Crash Course Syllabus: ${LearningComponents.AlgosAndDataStructuresCrashCourse}</h2>
                                <ol>
                                  <li>Part I: Asymptotic Runtime Analysis
                                    <ul>
                                      <li>Big O Notation</li>
                                      <li>Space Complexity</li>
                                      <li>Runtime Complexity</li>
                                    </ul>
                                  </li>
                                  <li>Part II: Core Data Structures & Algorithms
                                    <ul>
                                      <li>Array</li>
                                      <li>String</li>
                                      <li>Hash Table</li>
                                      <li>Stacks & Queues</li>
                                      <li>Matrix/2-D Array</li>
                                      <li>Binary Search</li>
                                      <li>Sorting</li>
                                      <li>Recursion</li>
                                      <li>Linked List</li>
                                      <li>Doubly-Linked List</li>
                                      <li>Trees I</li>
                                      <li>Trees II</li>
                                    </ul>
                                  </li>
                                </ol>
`}
                />
                <CustomButton label='Register interest' onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} />
                <CustomButton label='Back' onClick={() => { setShowAlgosAndDatastructuresCrashCourseSyllabusModal(false) }} />
              </>

            }
            onClose={() => { setShowAlgosAndDatastructuresCrashCourseSyllabusModal(false) }}
            backgroundColor='#2c3e50'
          />)
        }
      </>

    )
  }

  const renderAlgosAndDataStructuresCrashCourseIntermediate = () => {
    const detailsHtml: string = `
        <p>
        This crash course builds on your foundational knowledge and covers more advanced data structures, equipping you with the knowledge you need to tackle the more challenging questions you'll encounter in more competitive SWE interviews (FAANG, Quant firms, etc.)
        </p>
        <p>
        <ul>
          <li>Number of Sessions: 15</li>
          <li>Session length: 40 minutes - 1 hour</li>
        </ul>
        </p>
        `;
    return (
      <>

        <CrashCourseDetails detailsHtml={detailsHtml} />
        <CrashCourseCommonFeatureList />
        <CustomButton label='Register interest' onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} />

        <CustomButton
          label='View Course Content'
          onClick={() => setShowAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal(true)}
          bgColor='#2c3e50'
        />
        {showAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal && (
          <Modal
            content={
              <>
                <CrashCourseSyllabus

                  syllabusHtml={`
                                <h2>Crash Course Syllabus: ${LearningComponents.AlgosAndDataStructuresCrashCourseIntermediate}</h2>
                                <ol>
                                  <li>Heap & Priority Queue</li>
                                  <li>Divide and Conquer</li>
                                  <li>Dynamic Programming
                                    <ul>
                                      <li>1-D</li>
                                      <li>2-D</li>
                                    </ul>
                                  </li>
                                  <li>Graphs</li>
                                  <li>Depth-First Search</li>
                                  <li>Breadth-First Search</li>
                                  <li>Topological Sort</li>
                                  <li>Combinatorics
                                    <ul>
                                      <li>Permutations</li>
                                      <li>Combinations</li>
                                    </ul>
                                  </li>
                                  <li>Object-Oriented Programming</li>
                                  <li>Bit Manipulation</li>
                                  <li>Trie</li>
                                  <li>Greedy Algorithms</li>
                                </ol>
`}
                />
                <CustomButton label='Register interest' onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} />
                <CustomButton label='Back' onClick={() => { setShowAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal(false) }} />
              </>

            }
            onClose={() => { setShowAlgosAndDatastructuresIntermediateCrashCourseSyllabusModal(false) }}
            backgroundColor='#2c3e50'
          />)
        }
      </>

    )
  }

  const renderSystemDesignCrashCourse = () => {
    const detailsHtml: string = `
        <p>We cover everything you need to know to ace your System Design interviews. We'll give you a strong foundation, and use this pre-requisite knowledge to sovle the type of problems you'll encounter in interviews at top SWE employers (FAANG, Quant firms etc).</p>
        <p>
        <ul>
          <li>Number of Sessions: 15</li>
          <li>Session length: 40 minutes - 1 hour</li>
        </ul>
        </p>
        `;
    return (
      <>
        <CrashCourseDetails detailsHtml={detailsHtml} />
        <CrashCourseCommonFeatureList />
        <CustomButton onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} label='Register interest' />
        <CustomButton
          label='View Course Content'
          onClick={() => setShowSystemDesignCrashCourseSyllabusModal(true)}
          bgColor='#2c3e50'
        />
        {showSystemDesignCrashCourseSyllabusModal && (
          <Modal
            content={
              <>
                <CrashCourseSyllabus
                  syllabusHtml={`
                                <h2>Crash Course Syllabus: ${LearningComponents.SystemDesignCrashCourse}</h2>
                                <p>The main objective of this course is to ensure you have what it takes to get a hire+ decision in system design interviews at top tech companies. It will consists of a combination of online content which you can self-study at your own pace, and live workshops where you can practice solving real interview problems with the guidance of an experienced instructor.</p>
                                <ol>
                                  <li>Framework for Nailing System Design Interviews
                                    <ul>
                                      <li>Functional requirements</li>
                                      <li>Non-functional requirements
                                        <ul>
                                          <li>Performance</li>
                                          <li>Consistency Models
                                            <ul>
                                              <li>Read-after-write consistency</li>
                                              <li>Eventual consistency</li>
                                              <li>Strong consistency</li>
                                              <li>Quorum consistency</li>
                                            </ul>
                                          </li>
                                          <li>Availability & Fault Tolerance</li>
                                          <li>Partition Tolerance</li>
                                          <li>CAP theorem</li>
                                          <li>Scalability</li>
                                          <li>Security</li>
                                          <li>Reliability</li>
                                          <li>Data Durability</li>
                                          <li>Data Integrity</li>
                                          <li>Cost</li>
                                          <li>Extensibility</li>
                                          <li>Regulatory Compliance</li>
                                        </ul>
                                      </li>
                                      <li>Clarifying requirements</li>
                                      <li>Defining scope & priorities
                                        <ul>
                                          <li>Estimation & Capacity Planning</li>
                                        </ul>
                                      </li>
                                      <li>Understanding usage patterns</li>
                                      <li>Iterative Design
                                        <ul>
                                          <li>Pieces of the puzzle</li>
                                          <li>High level design</li>
                                          <li>Detailed design, trade-offs & decision justification</li>
                                        </ul>
                                      </li>
                                      <li>Prioritized deep dives</li>
                                      <li>Influencing the interviewer</li>
                                    </ul>
                                  </li>
                                  <li>Fundamental Concepts
                                    <ul>
                                      <li>Request Routing (DNS, Load Balancer, Reverse Proxy, API Gateway, Rate limiting, CDN)</li>
                                      <li>Restful APIs (including JWT, cookies & sessions)</li>
                                      <li>Intro to Microservices Architecture</li>
                                      <li>Scaling Services I </li>
                                      <li>In-memory Data Stores I (Redis, Memcached)</li>
                                      <li>In-memory Data Stores II (Caching Strategies)</li>
                                      <li>Persistent Data Stores (SQL, NoSQL)
                                      </li>
                                      <li>Persistent Data Stores at Scale (Partitioning, Sharding, Replication)</li>
                                      <li>Datastore Selection</li>
                                      <li>Queues I (Kafka, RabbitMQ, Amazon SQS, Dead Letter Queues, Message Brokers, Pub/Sub, Workers)</li>
                                      <li>Queues II (Kafka, RabbitMQ, Amazon SQS, Dead Letter Queues, Message Brokers, Pub/Sub, Workers)</li>
                                      <li>Scaling Services II
                                    </ul>
                                  </li>
                                  <li>Practice Problems
                                    <ul>
                                      <li>Practice sessions to help reinforce designing for
                                        <ul>
                                          <li>Low latency</li>
                                          <li>High throughput</li>
                                          <li>High availability</li>
                                          <li>Consistency</li>
                                          <li>Scaling
                                            <ul>
                                              <li>Millions of users/requests</li>
                                              <li>Billions of users/requests</li>
                                            </ul>
                                          </li>
                                          <li>Reliability</li>
                                          <li>Data loss minimization</li>
                                          <li>Security (include secure protocols, JWT, cookies & sessions, etc.)</li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>Weekly Live Hands-on Workshops (2-3 per week)
                                    <ul>
                                      <li>We will solve real interview problems like:</li>
                                      <ul>
                                        <li>Design a dating application e.g. Tinder</li>
                                        <li>Design a ride-hailing service e.g. Waymo, Uber</li>
                                        <li>Design a music/video streaming service e.g. Spotify, YouTube</li>
                                        <li>and more</li>
                                      </ul>
                                    </ul>
                                  </li>
                                </ol>

                    `}
                />
                <CustomButton label='Register interest' onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} />
                <CustomButton label='Back' onClick={() => { setShowSystemDesignCrashCourseSyllabusModal(false) }} />
              </>

            }
            onClose={() => { setShowSystemDesignCrashCourseSyllabusModal(false) }}
            backgroundColor='#2c3e50'
          />)
        }
      </>

    )
  }

  const renderOptimizeYourInterviewPerformanceCrashCourse = () => {
    const detailsHtml: string = `
        <p>This crash course prepares you for tech interviews with the likes of FAANG companies and other employers of software engineers. We cover how to tell you're truly interview-ready, uncovering problems in disguise, optimal solution crafting, effective communication, bug prevention, and time management. Learn to handle high-pressure scenarios with ease, impress interviewers, and stand out from the competition. Includes preparation strategies, learning techniques, interview tactics, and common problem-solving frameworks.</p>
        
        <p>
        <ul>
          <li>Number of Sessions: 10</li>
          <li>Session length: 40 minutes - 1 hour</li>
        </ul>
        </p>
        `;
    return (
      <>
        <CrashCourseDetails detailsHtml={detailsHtml} />
        <CrashCourseCommonFeatureList />
        <CustomButton onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} label='Register interest' />

        <CustomButton
          label='View Course Content'
          onClick={() => setShowOptimizeYourInterviewPerformanceCrashCourseSyllabusModal(true)}
          bgColor='#2c3e50'
        />
        {showOptimizeYourInterviewPerformanceCrashCourseSyllabusModal && (
          <Modal
            content={
              <>
                <CrashCourseSyllabus

                  syllabusHtml={`
                                <h2>Crash Course Syllabus: ${LearningComponents.OptimizeYourInterviewPerformanceCrashCourse}</h2>
                                <ol>
                                  <li>Breaking into FAANG & Other Top Tech Employers in the current year
                                    <ul>
                                      <li>Big Tech: Meta, Apple, Nvidia, Microsoft, Google, Netflix etc</li>
                                      <li>AI companies: Open AI, Anthropic, Perplexity AI etc</li>
                                      <li>Quant Firms: Jane Street, Citadel, Hudson River Trading, Two Sigma etc</li>
                                      <li>Other Tech Firms: Dropbox, Databricks, Snowflake etc</li>
                                    </ul>
                                  </li>
                                  <li>Tailored Interview Preparation Strategies
                                    <ul>
                                      <li>FAANG & Other Top Tech Employers
                                        <ul>
                                          <li>Interview Readiness
                                            <ul>
                                              <li>How to Prepare for Interview Conditions</li>
                                              <li>How Do You Know You're Ready?</li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>Optimal Learning Techniques
                                        <ul>
                                          <li>Phased Learning Approach</li>
                                          <li>Learning to Remember</li>
                                          <li>Associative Learning for Solving Unfamiliar Problems</li>
                                        </ul>
                                      </li>
                                      <li>Interview Techniques
                                        <ul>
                                          <li>Rigorous Test Case Generation & Edge Case Handling</li>
                                          <li>Crafting Optimal Solutions</li>
                                          <li>Implementing Bug-Free Code Quickly</li>
                                          <li>Time Management</li>
                                          <li>Influencing the Interviewer</li>
                                          <li>Staying Calm Under Pressure</li>
                                          <li>Handling Tricky Interview Scenarios (brain freeze, back-to-back interviews, etc.)</li>
                                          <li>Post-Interview (Handling Offers & Rejections)</li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>Problem-Solving Frameworks and Patterns
                                    <ul>
                                      <li>Uncovering Problems in Disguise</li>
                                      <li>Sliding Window</li>
                                      <li>Two Pointers</li>
                                      <li>Fast and Slow Pointers</li>
                                      <li>Merge Intervals</li>
                                      <li>Cyclic Sort</li>
                                      <li>In-place Algorithms</li>
                                      <li>Breadth First Search</li>
                                      <li>Depth First Search</li>
                                      <li>Two Heaps</li>
                                      <li>Subsets</li>
                                      <li>Modified Binary Search</li>
                                      <li>Top K Elements</li>
                                      <li>K-way Merge</li>
                                      <li>Topological Sort</li>
                                      <li>Min Heap</li>
                                      <li>Shortest Path Algorithms</li>
                                      <li>Dynamic Programming</li>
                                      <li>Backtracking</li>
                                    </ul>
                                  </li>
                                </ol>

                `}
                />
                <CustomButton label='Register interest' onClick={() => openUrlInNewTab(REGISTRATION_FORM_FOR_CRASH_COURSES_URL)} />
                <CustomButton label='Back' onClick={() => { setShowOptimizeYourInterviewPerformanceCrashCourseSyllabusModal(false) }} />
              </>

            }
            onClose={() => { setShowOptimizeYourInterviewPerformanceCrashCourseSyllabusModal(false) }}
            backgroundColor='#2c3e50'
          />)
        }
      </>

    )
  }

  const renderCodingAndBehaviouralWorkShop = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <EmojiObjectsIcon />&nbsp; Optimal Problem-Solving Strategies: Learn how to recognise optimal solutions for algorithm problems and techniques for acing behavioral questions.
            </li>

            <li>
              <BugReportIcon />&nbsp; Writing Bug-Free Code Under Pressure: Master techniques to code accurately and efficiently under interview conditions.
            </li>

            <li>
              <PsychologyIcon />&nbsp; Retain and Remember Effectively: Discover neuroscience-backed strategies to help you retain knowledge and perform well during interviews.
            </li>

            <li>
              <MapIcon />&nbsp; Personalized Learning Roadmap: Create a customized plan to maximize your preparation and optimize your study efforts.
            </li>

            <li>
              <EventAvailableIcon />&nbsp; Limited Spots Available: Secure your seat in one of our upcoming workshops to enhance your coding and interview skills.
            </li>
          </ul>
        </div>


        <CustomButton onClick={() => openUrlInNewTab(CODING_AND_BEHAVIOURAL_WORKSHOP_URL)} label='Join Next Session (FREE)' />
      </>

    )
  }

  const renderSystemDesignWorkShop = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <BuildIcon />&nbsp; Hands-On Practice: Solve system design interview problems during the workshop to build practical skills.
            </li>

            <li>
              <LightbulbIcon />&nbsp; Expert Guidance: Learn from seasoned professionals who will provide insights and feedback tailored to your needs.
            </li>

            <li>
              <QuestionAnswerIcon />&nbsp; Interactive Learning: Small group settings ensure personalized attention and opportunities for Q&A.
            </li>

            <li>
              <TrendingUpIcon />&nbsp; Master Key Techniques: Learn how to effectively scope, prioritize, deep dive and communicate your design decisions to stand out in interviews.
            </li>

            <li>
              <EventAvailableIcon />&nbsp; Limited Spots: Reserve your spot today to secure a place in one of our upcoming workshops.
            </li>
          </ul>
        </div>

        <CustomButton onClick={() => openUrlInNewTab(SYSTEM_DESIGN_WORKSHOP_URL)} label='Join Next Session (FREE)' />
      </>

    )
  }

  const renderSystemDesignCourse = () => {
    return (
      <>
        <div className='assessments-top-row'>
          <ul className="feature-list">
            <li>
              <TrendingUpIcon />&nbsp; Progressive Skill Building: Start with foundational challenges and advance to complex interview problems at your own pace.
            </li>
            
            <li>
              <MapIcon />&nbsp; Comprehensive Coverage: Includes essential concepts and strategies to ace system design interviews.
            </li>

            <li>
              <EmojiObjectsIcon />&nbsp; Easy-to-Understand Reference Solutions: Step-by-step walkthroughs that mimic an ideal candidate’s thought process, depicting the standard needed for a hire+ decision.
            </li>

            <li>
              <BusinessCenterIcon />&nbsp; Real-Interview Challenges: Solve system design problems asked in interviews at big tech companies like Meta, Google, and Netflix.
            </li>

            <li>
              <BuildIcon />&nbsp; Hands-On Focus: Prioritizes learning by doing with practical exercises, avoiding unnecessary theoretical overload to enhance practical skills needed for interviews.
            </li>

          </ul>
      </div>

        <CustomButton 
          onClick={() => navigate(`${LEARNING_PAGE_PATH}/${LEARNING_PAGE_COURSES_SUB_PATH}/${SYSTEM_DESIGN_COURSE_ID}`)}
          label='View Sytem Design Course'
          bgColor='#703733'
        />
      </>
    )
  }





  const renderViewByMode = (mode: LearningComponents) => {
    switch (mode) {
      case LearningComponents.LearningPath:
        return renderLearningPath();
      case LearningComponents.IdentifyKnowledgeGaps:
        return renderDiscoverWeaknesses();
      case LearningComponents.AssessInterviewReadiness:
        return renderInterviewReadinessAssessment();
      case LearningComponents.Coaching:
        return renderCoachingFeature();
      case LearningComponents.InstructorLedCrashCourses:
        return renderCrashCourses();
      case LearningComponents.AlgosAndDataStructuresCrashCourse:
        return renderAlgosAndDataStructuresCrashCourse();
      case LearningComponents.AlgosAndDataStructuresCrashCourseIntermediate:
        return renderAlgosAndDataStructuresCrashCourseIntermediate();
      case LearningComponents.SystemDesignCrashCourse:
        return renderSystemDesignCrashCourse();
      case LearningComponents.OptimizeYourInterviewPerformanceCrashCourse:
        return renderOptimizeYourInterviewPerformanceCrashCourse();
      case LearningComponents.HumanMockInterviews:
        return renderHumanMockInterviews();
      case LearningComponents.AIMockInterviews:
        return renderAIMockInterviews();
      case LearningComponents.SystemDesignWorkShop:
        return renderSystemDesignWorkShop();
      case LearningComponents.CodingAndBehaviouralWorkShop:
        return renderCodingAndBehaviouralWorkShop();
      case LearningComponents.SystemDesignCourse:
        return renderSystemDesignCourse();
      default:
        return null;
    }
  }

  ////////////////////////////////
  // useEffects
  ////////////////////////////////
  useEffect(() => {
    const learningPath = getLearningPathFromDataLayer();
    setHasLearningPath(!!learningPath); // `!!learningPath` is a trick to convert a string to a boolean
  }, []);

  return (
    <div className='assessments-menu'>
      {renderViewByMode(mode)}
    </div>
  );
};

export default LearningHomeSubMenu;