import { getUserDetailsByEmail, logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { ProgrammingLanguage } from "../pages/Settings";
import { ChallengeTestCase, EMAIL_TO_USER_ID_MAPPING, LOCAL_STORAGE_LEARNING_PATH, Language, TestCase, Tutorial } from "./Constants";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import sanitizeHtml from 'sanitize-html';
import DropdownItem from './DropdownItem';
import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

export const doNothing = () => { }; // does nothing

export function convertChallengeTestCaseToCodeExecutionTestCase(testCases: ChallengeTestCase[]): TestCase[] {
  const codeExecutionTestCases: TestCase[] = [];
  testCases.forEach((testCase) => {
    codeExecutionTestCases.push({
      stdin: testCase.input,
      expected_output: testCase.expected_output,
    });
  });
  return codeExecutionTestCases;
}

export function convertLanguageToCodeExecutionLanguage(language: Language): string {
  switch (language) {
    case Language.Python:
      return "Python";
    case Language.JavaScript:
      return "JavaScript";
    case Language.Java:
      return "Java";
    case Language.Go:
      return "Go";
    case Language.C:
      return "C";
    case Language.Typescript:
      return "TypeScript";
    case Language.Swift:
      return "Swift";
    case Language.Kotlin:
      return "Kotlin";
    case Language['C++']:
      return "C++";
    case Language['C#']:
      return "C#";
    default:
      console.error(`Language ${language} not supported`);
      return "";
  }
}

export function convertToProgrammingLanguage(language: Language): ProgrammingLanguage {
  switch (language) {
    case Language.Python:
      return ProgrammingLanguage.Python;
    case Language['C++']:
      return ProgrammingLanguage.Cpp;
    case Language.JavaScript:
      return ProgrammingLanguage.JavaScript;
    case Language.Java:
      return ProgrammingLanguage.Java;
    case Language.Go:
      return ProgrammingLanguage.Go;
    case Language['C#']:
      return ProgrammingLanguage.Csharp;
    case Language.C:
      return ProgrammingLanguage.C;
    case Language.Typescript:
      return ProgrammingLanguage.Typescript;
    case Language.Swift:
      return ProgrammingLanguage.Swift;
    case Language.Kotlin:
      return ProgrammingLanguage.Kotlin;
    default:
      throw new Error(`Language ${language} not supported in ProgrammingLanguage enum.`);
  }
}

// Enables Language type to be used as a key to CodeBlockByLanguage type
export function mapLanguagetoCodeBlockLanguageLabel(language: Language) { // NITO-language-single-source
  switch (language) {
    case Language.Python:
      return 'Python';
    case Language['C++']:
      return 'C++';
    case Language.Java:
      return 'Java';
    case Language.JavaScript:
      return 'JavaScript';
    case Language['C#']:
      return 'C#';
    case Language.Go:
      return 'Go';
    case Language.C:
      return 'C';
    case Language.Typescript:
      return 'TypeScript'
    case Language.Kotlin:
      return 'Kotlin'
    case Language.Swift:
      return 'Swift'
  }
}

export class ReactSyntaxHighlighterUtils {
  static DEFAULT_LANGUAGE = 'plaintext';

  static SupportedLanguages = {
    Python: "python",
    'C++': "cpp",
    JavaScript: "javascript",
    Java: "java",
    Go: "go",
    'C#': "csharp",
    C: "c",
    TypeScript: 'typescript',
    Swift: 'swift',
    Kotlin: 'kotlin',
    Ruby: 'ruby',
  };

  static SyntaxHighlighterLanguages = new Set(Object.values(ReactSyntaxHighlighterUtils.SupportedLanguages));

  static getLanguageHighlightName(language: string | undefined | null): string {
    if (!language) {
      return this.DEFAULT_LANGUAGE;
    }
    const lowerCaseLanguage = language.toLowerCase();
    if (this.SyntaxHighlighterLanguages.has(lowerCaseLanguage)) {
      return lowerCaseLanguage;
    }
    const mappedLanguage = this.mapLanguage(lowerCaseLanguage);
    return this.SyntaxHighlighterLanguages.has(mappedLanguage) ? mappedLanguage : this.DEFAULT_LANGUAGE;
  }

  private static mapLanguage(language: string): string {
    const languageMapping: { [key: string]: string } = {
      "c++": "cpp",
      "c#": "csharp"
    };
    return languageMapping[language] || language;
  }
}

export function cacheUserIdByEmailMapping(email: string, userId: number): void {
  const emailToUserIdMapping = JSON.parse(localStorage.getItem(EMAIL_TO_USER_ID_MAPPING) || '{}');
  emailToUserIdMapping[email] = userId;
  localStorage.setItem(EMAIL_TO_USER_ID_MAPPING, JSON.stringify(emailToUserIdMapping));
}

// Define a helper function for getting the userId based on an email
export function getUserIdByEmailFromCache(email: string): number | null {
  const emailToUserIdMapping = JSON.parse(localStorage.getItem(EMAIL_TO_USER_ID_MAPPING) || '{}');
  return emailToUserIdMapping[email] || null;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function getUserIdByEmailFromBackend(email: string, maxRetries: number = 3): Promise<number> {
  let retryCount = 0;
  let lastError;

  while (retryCount < maxRetries) {
    try {
      const userDetails = await getUserDetailsByEmail(email);
      if (userDetails && userDetails.id) {
        return userDetails.id;
      } else {
        // Handle case where user details don't have an id or are not as expected
        console.error("Received unexpected user details format", userDetails);
        retryCount++;
        if (retryCount < maxRetries) await delay(1000);  // wait for 1 second before retrying
      }
    } catch (error) {
      lastError = error;  // save the last error
      console.error("Error fetching user details by email", error);
      retryCount++;
      if (retryCount < maxRetries) await delay(1000);  // wait for 1 second before retrying
    }
  }

  console.error("Failed to fetch user details after maximum retries");
  if (lastError) {
    throw lastError;  // throw the last encountered error
  } else {
    throw new Error("Received unexpected user details format after maximum retries");
  }

}


export function learningPathExistsInDataLayer(): boolean {
  const learningPath = localStorage.getItem(LOCAL_STORAGE_LEARNING_PATH);
  return Boolean(learningPath); // this will return false if learningPath is null or undefined
}

//////////////////////////////////////////////////////////
// Helper functions for rendering content in dropdowns //
//////////////////////////////////////////////////////////


// Configuration for sanitizeHtml
const sanitizeConfig = {
  // Include all default allowed tags plus 'img'
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),

  // Allow the 'src' attribute for 'img' tags and 'class' for all tags
  allowedAttributes: {
    '*': ['class'],  // Allow 'class' on all tags
    'img': ['src'],  // Allow 'src' on 'img' tags
  },

  // Allow standard schemes such as data, http, and https
  allowedSchemes: ['data', 'http', 'https'],

};


// Define an interface for the props
interface CodeComponentProps {
  node: any; // Replace 'any' with a more specific type if available
  inline: boolean;
  className?: string;
  children?: React.ReactNode;
  // Define other props as needed
  [key: string]: any; // For additional properties
}


enum ContentType {
  HTML = 'html',
  MARKDOWN = 'markdown',
  IMAGE_URL = 'image'
}


export function renderContentByFormat(title: string, content: string, type: ContentType, inDropdown: boolean = false): JSX.Element {
  let renderedContent;
  if (type === ContentType.MARKDOWN) {
    const components = {
      code({ node, inline, className, children, ...props }: CodeComponentProps) {
        const match = /language-(\w+)/.exec(className || '')
        return !inline && match ? (
          <SyntaxHighlighter style={solarizedlight} language={match[1]} PreTag="div" {...props}>
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }
    };
    renderedContent = (
      <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"' }}>
        {/* @ts-ignore */}
        <ReactMarkdown components={components}>{content}</ReactMarkdown>
      </div>
    );
  } else if (type === ContentType.HTML) {
    renderedContent = (
      <MathJaxContext> {/*This provides a context for MathJax to render the LaTeX math equations properly.*/}
        <MathJax dynamic> {/*The dynamic prop ensures that MathJax will re-process any dynamic changes in the content, such as new math expressions.*/}
          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(content, sanitizeConfig) }} />
        </MathJax>
      </MathJaxContext>
    );
  } else if (type === ContentType.IMAGE_URL) {
    renderedContent = <div><img src={content} alt={title} style={{ width: '100%' }} /></div>;
  }
  else { /* will simply render the content as is */
    renderedContent = <div>{content}</div>;
  }

  return inDropdown ? <DropdownItem title={title}>{renderedContent}</DropdownItem> : renderedContent;
}

export function renderContentInDropdownByFormat(title: string, content: string, type: ContentType): JSX.Element {
  return renderContentByFormat(title, content, type, true);
}

export function renderTutorialContent(tutorial: Tutorial): JSX.Element | null {
  if (tutorial.lesson.markdown) {
    return renderContentByFormat(tutorial.lesson.title, tutorial.lesson.markdown, ContentType.MARKDOWN);
  } else if (tutorial.lesson.html) {
    return renderContentByFormat(tutorial.lesson.title, tutorial.lesson.html, ContentType.HTML);
  }
  return null;
}

/*
The interface needs to be improved, 
Define at least one parameter, and only one will be used based on the precedence of the parameters (the ordering)
*/
export function renderContentInDropDown(
  title: string,
  markdown: string | null | undefined,
  html: string | null | undefined,
  imgUrl: string | null | undefined
): JSX.Element | null {
  if (markdown) {
    return renderContentInDropdownByFormat(title, markdown, ContentType.MARKDOWN);
  } else if (html) {
    return renderContentInDropdownByFormat(title, html, ContentType.HTML);
  } else if (imgUrl) {
    return renderContentInDropdownByFormat(title, imgUrl, ContentType.IMAGE_URL);
  } else {
    return null;
  }
}

export function renderContent(title: string, markdown: string | null | undefined, html: string | null | undefined): JSX.Element | null {
  if (markdown) {
    return renderContentByFormat(title, markdown, ContentType.MARKDOWN);
  } else if (html) {
    return renderContentByFormat(title, html, ContentType.HTML);
  } else {
    return null;
  }
}