import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight, coy, twilight, okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Modal from '../utils/Modal';
import '../css/CodeSolutionViewer.css';
import { CodeBlockByLanguage } from './Constants';
import { ReactSyntaxHighlighterUtils } from './HelperFunction';
import { FaClipboard, FaClipboardCheck } from 'react-icons/fa'; // Using react-icons

// Define the Theme enum and mapping
enum Theme {
  SolarizedLight = 'SolarizedLight',
  Coy = 'Coy',
  Twilight = 'Twilight',
  Okaidia = 'Okaidia',
}

const themeStyles: Record<Theme, any> = {
  [Theme.SolarizedLight]: solarizedlight,
  [Theme.Coy]: coy,
  [Theme.Twilight]: twilight,
  [Theme.Okaidia]: okaidia,
};

// Define a ranking for the programming languages
const languageRanking: Record<string, number> = {
  Python: 1,
  JavaScript: 2,
  Java: 3,
  'C++': 4,
  'C#': 5,
  TypeScript: 6,
  Go: 7,
  C: 8,
  Swift: 9,
  Kotlin: 10,
  // Add other languages and ranks as needed
};

interface CodeSolutionViewerProps {
  solutions: CodeBlockByLanguage;
}

const CodeSolutionViewer: React.FC<CodeSolutionViewerProps> = React.memo(({ solutions }) => {
  const languages = useMemo(() => {
    // Get the list of languages available in solutions
    const availableLanguages = Object.keys(solutions).filter(
      (lang) => solutions[lang as keyof CodeBlockByLanguage]
    ) as (keyof CodeBlockByLanguage)[];

    // Sort languages by their defined rank; unknown languages get ranked lower
    return availableLanguages.sort((a, b) => {
      const rankA = languageRanking[a] ?? Infinity;
      const rankB = languageRanking[b] ?? Infinity;
      return rankA - rankB;
    });
  }, [solutions]);

  // Set "Python" as the default language, or use the first available language
  const [selectedLanguage, setSelectedLanguage] = useState<keyof CodeBlockByLanguage>(
    languages.includes('Python' as keyof CodeBlockByLanguage) ? 'Python' : languages[0]
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedTheme, setSelectedTheme] = useState<Theme>(Theme.Okaidia);
  
  // State for copy feedback**
  const [isCopied, setIsCopied] = useState<boolean>(false);
  
  // Ref to store timeout ID which is useful when unmounting the component to avoid memory leaks (e.g. if a component is unmouted before the timeout is cleared)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLanguageSelect = useCallback(
    (language: keyof CodeBlockByLanguage) => setSelectedLanguage(language),
    []
  );
  const handleThemeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedTheme(event.target.value as Theme);
    },
    []
  );
  const toggleModal = useCallback(() => setShowModal((prev) => !prev), []);

  const codeContent = useMemo(
    () => (
      <SyntaxHighlighter
        language={ReactSyntaxHighlighterUtils.getLanguageHighlightName(selectedLanguage)}
        style={themeStyles[selectedTheme]}
        showLineNumbers
      >
        {solutions[selectedLanguage] || 'Solution not available for this language'}
      </SyntaxHighlighter>
    ),
    [selectedLanguage, selectedTheme, solutions]
  );


  const handleCopyCode = useCallback(() => {
    const codeString = solutions[selectedLanguage] || '';
    navigator.clipboard.writeText(codeString)
      .then(() => {
        setIsCopied(true);
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        // Set a new timeout and store its ID
        timeoutRef.current = setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        // Optionally, handle the error, e.g., show a message to the user
      });
  }, [solutions, selectedLanguage]);

  
  useEffect(() => {// Cleanup timeout on unmount**
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="code-solution-viewer">
      {/* Language Tabs */}
      <div className="language-tabs">
        {languages.map((language) => (
          <button
            key={language}
            onClick={() => handleLanguageSelect(language)}
            className={`language-tab ${selectedLanguage === language ? 'selected' : ''}`}
          >
            {language}
          </button>
        ))}
      </div>

      {/* Controls Container: Theme Selector and View Modal Button */}
      <div className="controls-container">
        <ThemeSelector selectedTheme={selectedTheme} onThemeChange={handleThemeChange} />
        <button onClick={toggleModal} className="view-modal-button">
          View in Modal
        </button>
      </div>

      {/* Code Display Area */}
      <div className="code-display">
        <button className="copy-button" onClick={handleCopyCode} aria-label="Copy code to clipboard">
          {isCopied ? <FaClipboardCheck /> : <FaClipboard />}
        </button>
        {codeContent}
      </div>

      {/* Modal for Code Display */}
      {showModal && (
        <Modal onClose={toggleModal} content={<div className="modal-code-display">{codeContent}</div>} />
      )}
    </div>
  );
});

export default CodeSolutionViewer;

// ThemeSelector Component
interface ThemeSelectorProps {
  selectedTheme: Theme;
  onThemeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ThemeSelector: React.FC<ThemeSelectorProps> = ({ selectedTheme, onThemeChange }) => (
  <div className="theme-selector">
    <label htmlFor="theme-select">Theme:</label>
    <select id="theme-select" value={selectedTheme} onChange={onThemeChange}>
      {Object.values(Theme).map((theme) => (
        <option key={theme} value={theme}>
          {theme}
        </option>
      ))}
    </select>
  </div>
);
