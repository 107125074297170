
import { collection, query, where, getDocs, QuerySnapshot, DocumentData, doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { logToBackendLogFile } from "@/externalLayerAccessor/BackEndRequests";
import { UserSubscription, UserSubscriptionTier } from "./models/firebaseModels";


const FIREBASE_ALPHA_PHASE_COLLECTION_NAME: Readonly<string> = "alpha-phase"; 
const FIREBASE_ALPHA_PHASE_USERS_DOC_NAME: Readonly<string> = "users";

export const fetchSubscriptionByEmailNoThrow = async (userEmail: string): Promise<UserSubscription | null> => {
    try {
        const subscriptionsCollectionRef = collection(db, "subscriptions");
        const fetchSubscriptionByEmailQuery = query(subscriptionsCollectionRef, where("email", "==", userEmail.toLowerCase()));
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(fetchSubscriptionByEmailQuery);

        if (querySnapshot.empty) {
            return null;
        }

        //! reaching here means at least one document was found in the collection matching the query

        if (querySnapshot.docs.length > 1) {
            logToBackendLogFile(`More than one subscription found for email: ${userEmail}`, "warn");
        }

        const firstSubscriptionDoc = querySnapshot.docs[0];
        const subscriptionData = firstSubscriptionDoc.data();

        // Ensure all required fields are present
        if (!subscriptionData.subscriptionExpiry || !subscriptionData.subscriptionTier) {
            logToBackendLogFile(`Missing subscription data for email: ${userEmail}`, "error");
            return null;
        }

        // sanity check that emails match
        if (subscriptionData.email !== userEmail.toLowerCase()) {
            const errMsg = `Subscription email received: ${subscriptionData.email} does not match that provided: ${userEmail} in query`;
            logToBackendLogFile(errMsg, "error");
            return null;
        }

        const expiryDate: Date | null = subscriptionData.subscriptionExpiry ? subscriptionData.subscriptionExpiry.toDate() : null;
        const tier: UserSubscriptionTier = UserSubscriptionTier[subscriptionData.subscriptionTier as keyof typeof UserSubscriptionTier] || UserSubscriptionTier.FREE;

        return {
            displayName: subscriptionData.displayName,
            userEmail: subscriptionData.email,
            subscriptionTier: tier,
            subscriptionExpiry: expiryDate,
        };
    } catch (error) {
        //console.error(`Error fetching subscription for email: ${userEmail}: ${error}`);
        logToBackendLogFile(`Error fetching subscription for email: ${userEmail}: ${error}`, "error");
        return null;
    }
};

export async function addUserEmailToFirebaseUsersCollectionIfNew(email: string): Promise<void> {
    try {
        const usersDocRef = doc(db, FIREBASE_ALPHA_PHASE_COLLECTION_NAME, FIREBASE_ALPHA_PHASE_USERS_DOC_NAME);
        const userDocSnapshot = await getDoc(usersDocRef);

        if (userDocSnapshot.exists()) {
            // Document exists; ensure the email is added if not already present
            await updateDoc(usersDocRef, {
                emails: arrayUnion(email), // this will add the email if it doesn't already exist!
            });
        } else {
            // Document doesn't exist; create it with the user's email
            await setDoc(usersDocRef, {
                emails: [email],
            });
        }
    } catch (error) {
        logToBackendLogFile(`Error adding user email: ${email} to backend: ${error}`, "error");
        throw new Error("Failed to update user information. Please try again or contact support.");
    }
}


export async function doesUserEmailExistInFirebaseUsersCollection(email: string): Promise<boolean> {
    try {
        const usersDocRef = doc(db, FIREBASE_ALPHA_PHASE_COLLECTION_NAME, FIREBASE_ALPHA_PHASE_USERS_DOC_NAME);
        const userDocSnapshot = await getDoc(usersDocRef);

        if (userDocSnapshot.exists()) {
            const emails: string[] = userDocSnapshot.data().emails || [];
            return emails.includes(email);
        }

        // If the document doesn't exist, the email cannot exist
        return false;
    } catch (error) {
        logToBackendLogFile(`Error checking if user email exists: ${email}, Error: ${error}`, "error");
        throw new Error("Failed to check user email existence. Please try again or contact support.");
    }
}

