import { openDB, IDBPDatabase } from 'idb';
import debounce from 'lodash/debounce';

class CodeStorage {
  private static dbName = 'UserCodeDB';
  private static storeName = 'codeSubmissions';
  private static dbVersion = 3;
  private static dbInstance: IDBPDatabase | null = null;
  static readonly DEBOUNCE_SAVE_DELAY_MS = 500;
  static readonly MAX_USER_ENTRIES_BEFORE_EVICT = 100;

  private static async initDB(): Promise<IDBPDatabase> {
    if (!CodeStorage.dbInstance) {
      CodeStorage.dbInstance = await openDB(CodeStorage.dbName, CodeStorage.dbVersion, {
        upgrade(db, oldVersion, newVersion, transaction) {
          if (!db.objectStoreNames.contains(CodeStorage.storeName)) {
            const store = db.createObjectStore(CodeStorage.storeName, { keyPath: 'id' });
          }
          const store = transaction.objectStore(CodeStorage.storeName);
          if (!store.indexNames.contains('timestamp')) {
            store.createIndex('timestamp', 'timestamp', { unique: false });
          }
        },
      });
    }
    return CodeStorage.dbInstance;
  }

  static async saveOrUpdateCode(userId: string, taskId: string, language: string, code: string) {
    const db = await CodeStorage.initDB();
    const timestamp = new Date();
    const id = `${userId}_${taskId}_${language}`;
    await db.put(CodeStorage.storeName, { id, userId, taskId, language, code, timestamp });
  }

  static async fetchCode(userId: string, taskId: string, language: string): Promise<string | undefined> {
    const db = await CodeStorage.initDB();
    const data = await db.get(CodeStorage.storeName, `${userId}_${taskId}_${language}`);
    return data?.code;
  }

  static async evictOldestEntry(userId: string) {
    const db = await CodeStorage.initDB();
    const transaction = db.transaction(CodeStorage.storeName, 'readwrite');
    const store = transaction.objectStore(CodeStorage.storeName);
    const index = store.index('timestamp');
    const allUserIds = await index.getAllKeys(IDBKeyRange.only(userId));
    if (allUserIds.length > CodeStorage.MAX_USER_ENTRIES_BEFORE_EVICT) {
      await store.delete(allUserIds[0]);
    }
    await transaction.done;
  }

  static async safeSaveOrUpdateCode(userId: string, taskId: string, language: string, code: string) {
    try {
      await CodeStorage.saveOrUpdateCode(userId, taskId, language, code);
    } catch (error: unknown) {
      if (error instanceof DOMException && error.name === 'QuotaExceededError') {
        await CodeStorage.evictOldestEntry(userId);
        await CodeStorage.saveOrUpdateCode(userId, taskId, language, code);
      } else {
        console.error('Error saving code:', error);
      }
    }
  }

  static debouncedSave = debounce(CodeStorage.safeSaveOrUpdateCode, CodeStorage.DEBOUNCE_SAVE_DELAY_MS);
}

export default CodeStorage;