// src/utils/Spinner.tsx
import React from 'react';
import '../css/Spinner.css'; // Ensure this path is correct based on your project structure

const Spinner: React.FC = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
  </div>
);

export default Spinner;
