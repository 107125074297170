import React, { useState, useMemo } from "react";
import "../css/WhiteBoard.css";
// Define an enum for available drawing editors
enum WhiteBoardEditor {
  EXCALIDRAW = "EXCALIDRAW",
  // Add more editors here as needed, e.g.,
  // MURAL = "MURAL",
}
// Define a TypeScript interface for editor details
interface EditorDetails {
  name: string;
  url: string;
}
// Consolidate editor details (name and URL) into a single mapping
const whiteBoardEditorDetails: Record<WhiteBoardEditor, EditorDetails> = {
  [WhiteBoardEditor.EXCALIDRAW]: {
    name: "Excalidraw",
    url: "https://excalidraw.com",
  },
  // Add more editors here as needed, e.g.,
  // [WhiteBoardEditor.MURAL]: {
  //   name: "Mural",
  //   url: "https://mural.co",
  // },
};
// Define a default editor for fallback scenarios
const defaultEditor: WhiteBoardEditor = WhiteBoardEditor.EXCALIDRAW;
// Getter function to retrieve the editor details
const getEditorDetails = (editor: WhiteBoardEditor): EditorDetails => {
  if (editor in whiteBoardEditorDetails) {
    return whiteBoardEditorDetails[editor];
  } else {
    console.warn(
      `Editor "${editor}" not found in whiteBoardEditorDetails. Falling back to "${defaultEditor}".`
    );
    return whiteBoardEditorDetails[defaultEditor];
  }
};
const WhiteBoard: React.FC = () => {
  // Default to Excalidraw as the initial selected editor
  const [selectedEditor, setSelectedEditor] = useState<WhiteBoardEditor>(
    defaultEditor
  );
  const [iframeError, setIframeError] = useState<boolean>(false);
  const handleWhiteBoardEditorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value as WhiteBoardEditor;
    if (Object.values(WhiteBoardEditor).includes(selectedValue)) {
      setSelectedEditor(selectedValue);
      setIframeError(false); // Reset error on editor change
    } else {
      console.warn(
        `Selected editor "${selectedValue}" is not a valid WhiteBoardEditor. Falling back to "${defaultEditor}".`
      );
      setSelectedEditor(defaultEditor);
    }
  };
  // Memoize editor options for performance
  const editorOptions = useMemo(
    () => Object.values(WhiteBoardEditor),
    []
  );
  // Get the selected editor details (name, url)
  const { name: editorName, url: editorUrl } = getEditorDetails(selectedEditor);
  return (
    <div className="whiteboard-container">
      {/* Dropdown to select the drawing editor */}
      <div className="whiteboard-dropdown">
        <select
          id="editorSelect"
          value={selectedEditor}
          onChange={handleWhiteBoardEditorChange}
          className="editor-select"
        >
          {editorOptions.map((editor) => (
            <option key={editor} value={editor}>
              {whiteBoardEditorDetails[editor].name}
            </option>
          ))}
        </select>
      </div>
      {/* Conditionally render iframe or error message based on loading error */}
      {iframeError ? (
        <div className="error-message">
          Oops! The selected whiteboard editor could not be loaded. Please try another editor or check your internet connection.
        </div>
      ) : (
        <iframe
          src={editorUrl}
          width="100%"
          height="100%"
          title={editorName}
          className="whiteboard-iframe"
          onError={() => setIframeError(true)}  // Handle iframe loading error
        ></iframe>
      )}
    </div>
  );
};
export default WhiteBoard;