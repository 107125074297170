// Step 1: Define the Enum for Testimonial Types
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import '../css/MultiModalTestimonials.css';
import { ChevronLeft, ChevronRight } from "react-feather";

// Enum for testimonial types
export enum TestimonialType {
    TEXT = 'text',
    IMAGE = 'image',
    VIDEO = 'video',
}

// Enum for style types
export enum StyleType {
    CARD = 'card',
    STICKY_NOTE = 'sticky-note',
}

// Interfaces for testimonials

// Base interface for all testimonials
interface TestimonialBase {
    id: string; // Unique identifier for each testimonial
    userDisplayName: string; // Name of the user giving the testimonial
    type: TestimonialType; // Discriminator for the type of testimonial
    companyName?: string; // Optional company name
    companyLogoUrl?: string; // Optional URL for the company logo
}

// Text testimonial interface extending the base
interface TextTestimonial extends TestimonialBase {
    type: TestimonialType.TEXT; // Using enum for type
    text: string; // The text content of the testimonial
    textHtml?: string; // Optional HTML content for the testimonial
}

// Image testimonial interface extending the base
interface ImageTestimonial extends TestimonialBase {
    type: TestimonialType.IMAGE; // Using enum for type
    imageUrl: string; // URL to the image file
    altText: string; // Alt text for the image
    caption?: string; // Optional caption for the image
}

// one interface, but checks if caption is available or not, if there is, put video to top left of container, else 
// video is expanded and fill sticky notes
interface VideoTestimonial extends TestimonialBase {
  type: TestimonialType.VIDEO; // Using enum for type
  videoEmbedUrl: string; // This is not just a url to video but an embed url e.g. for youtube videos you can get it by clicking share and then embed
  altText: string; // Alt text for the video
  captionHtml?: string; // Optional caption for the image
}

// Union type for all possible testimonials (Currently only Text and Image)
export type Testimonial = TextTestimonial | ImageTestimonial | VideoTestimonial;

// Props interface for the main MultiModalTestimonials component
interface MultiModalTestimonialsProps {
    testimonials: Testimonial[]; // Array of testimonial objects (either text or image)
    itemsPerPage?: number; // Optional number of testimonials to display per page
    styleType?: StyleType; // Optional style type for text testimonials
}

// Props for Carousel, will have pagination included in here
interface MultiModalTestimonialBoardProps extends MultiModalTestimonialsProps {
}


interface TestimonialFooterProps {
  userDisplayName: string;
  companyName?: string;
  companyLogoUrl?: string;
}

const TestimonialFooter: React.FC<TestimonialFooterProps> = ({ userDisplayName, companyName, companyLogoUrl }) => {
  return (
    <div className="testimonial-footer">
      <span className="user-display-name">
        {userDisplayName}
      </span>
      {companyName && (
        <span className="company-name">
          {companyLogoUrl ? (
            <img
              src={companyLogoUrl}
              alt={companyName}
            />
          ) : (
            companyName
          )}
        </span>
      )}
    </div>
  );
};



interface TextTestimonialCardProps {
    testimonial: TextTestimonial; // Specific type for text testimonial
    styleType: StyleType; // Style type for the card
}

const TextTestimonialCard: React.FC<TextTestimonialCardProps> = React.memo(
    ({ testimonial, styleType }) => {
      return (
        <div className={`testimonial-card ${styleType}`}>
            {testimonial.textHtml ? (
                <div
                    dangerouslySetInnerHTML={{ __html: testimonial.textHtml }}
                />
            ) : (
                <p>{testimonial.text}</p>
            )}
            <TestimonialFooter
                userDisplayName={testimonial.userDisplayName}
                companyName={testimonial.companyName}
                companyLogoUrl={testimonial.companyLogoUrl}
            />
        </div>
    );
    }
  );

interface ImageTestimonialCardProps {
    testimonial: ImageTestimonial; // Specific type for image testimonial
    styleType: StyleType;
  }
  
const ImageTestimonialCard: React.FC<ImageTestimonialCardProps> = React.memo(
  ({ testimonial, styleType }) => {
    return (
      <div className={`image-testimonial-card ${styleType}`}>
        {/* Render image with alt text */}
        <img
          src={testimonial.imageUrl}
          alt={testimonial.altText}
          className="testimonial-image"
        />

        {/* Optional caption for the image */}
        {testimonial.caption && (
          <p className="image-caption">{testimonial.caption}</p>
        )}

        <TestimonialFooter
          userDisplayName={testimonial.userDisplayName}
          companyName={testimonial.companyName}
          companyLogoUrl={testimonial.companyLogoUrl}
        />
      </div>
    );
  }
);

// type for VideoTestimonial
interface VideoTestimonialCardProps {
  testimonial: VideoTestimonial;
  styleType: StyleType;
}

const VideoTestimonialCard: React.FC<VideoTestimonialCardProps> = React.memo(({ testimonial }) => {
  return (
    <div className="video-testimonial-card sticky-note">
        <iframe
          src={testimonial.videoEmbedUrl}
          title={testimonial.altText}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="video-thumbnail" // Apply styling class if needed
        ></iframe>

      {/* Optional caption below the video */}
      {testimonial.captionHtml && (
        <div
          className="video-caption"
          dangerouslySetInnerHTML={{ __html: testimonial.captionHtml }}
        />
      )}
      <TestimonialFooter
        userDisplayName={testimonial.userDisplayName}
        companyName={testimonial.companyName}
        companyLogoUrl={testimonial.companyLogoUrl}
      />
    </div>
  );
});


interface TestimonialDisplayProps {
    testimonial: Testimonial; // A single testimonial object of any type (Text or Image)
    styleType: StyleType; // Style type for text testimonials
}

const TestimonialDisplay: React.FC<TestimonialDisplayProps> = React.memo(
    ({ testimonial, styleType }) => {
      switch (testimonial.type) {
        case TestimonialType.TEXT:
          return (
            <TextTestimonialCard
              testimonial={testimonial}
              styleType={styleType}
            />
          );
        case TestimonialType.IMAGE:
          return <ImageTestimonialCard 
          testimonial={testimonial} 
          styleType={styleType} />;
        case TestimonialType.VIDEO:
          return <VideoTestimonialCard 
          testimonial={testimonial} 
          styleType={styleType}
          />;
        default:
          return null;
      }
    }
  );

interface PaginationControlsProps {
    currentPage: number; // Current active page
    totalPages: number; // Total number of pages
    onPageChange: (page: number) => void; // Callback function for changing pages
}

const PaginationControls: React.FC<PaginationControlsProps> = React.memo(
    ({ currentPage, totalPages, onPageChange }) => {
      return (
        <div className="pagination-controls">
          {/* Previous Button */}
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            aria-label="Previous page"
          >
            Previous
          </button>
  
          {/* Next Button */}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            aria-label="Next page"
          >
            Next
          </button>
        </div>
      );
    }
  );

const MultiModalTestimonials: React.FC<MultiModalTestimonialsProps> = ({
    testimonials,
    itemsPerPage = 3,
    styleType = StyleType.CARD,
  }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
  
    // Calculate total pages
    const totalPages = useMemo(
      () => Math.ceil(testimonials.length / itemsPerPage),
      [testimonials, itemsPerPage]
    );
  
    // Function to handle page change
    const handlePageChange = useCallback((newPage: number) => {
      setCurrentPage(newPage);
    }, []);
  
    // Calculate the testimonials to display on the current page
    const currentTestimonials = useMemo(() => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return testimonials.slice(startIndex, endIndex);
    }, [currentPage, itemsPerPage, testimonials]);
  
    return (
      <div className="multi-modal-testimonials">
        {/* Render current page of testimonials */}
        {currentTestimonials.map((testimonial) => (
          <TestimonialDisplay
            key={testimonial.id}
            testimonial={testimonial}
            styleType={styleType}
          />
        ))}
  
        {/* Pagination Controls */}
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    );
  };


// Extension Carousel Feature on MultiModalTestimonials
export const MultiModalTestimonialBoard: React.FC<MultiModalTestimonialBoardProps> = React.memo(
  ({testimonials,
    itemsPerPage = 3,
    styleType = StyleType.CARD}) => {

    const [currentPage, setCurrentPage] = useState<number>(1);
    const totalPages = useMemo(
      () => Math.ceil(testimonials.length / itemsPerPage),
      [testimonials, itemsPerPage]
    );

    const pageContainers = useMemo(
      () => Array.from({ length: totalPages }, (_, i) =>
          testimonials.slice(i * itemsPerPage, (i + 1) * itemsPerPage)
      ),
      [testimonials, itemsPerPage, totalPages]
    );


    const carouselSlidesRef = useRef<HTMLDivElement>(null);

    // Function to handle page change with wrap-around
    const handlePageChange = useCallback(
      (newPage: number) => {
        const wrappedPage = (newPage - 1 + totalPages) % totalPages + 1;
        if (wrappedPage !== currentPage) setCurrentPage(wrappedPage);
      },
      [totalPages, currentPage]
    );

    // Function to go to the previous page
    const prevPage = useCallback(() => handlePageChange(currentPage - 1), [handlePageChange, currentPage]);

    // Function to go to the next page
    const nextPage = useCallback(() => handlePageChange(currentPage + 1), [handlePageChange, currentPage]);

    useEffect(() => {
      const translatePercentage = `-${(currentPage - 1) * 100}%`;
      if (carouselSlidesRef.current) {
        carouselSlidesRef.current.style.setProperty('--carousel-translate', translatePercentage);
      }
    }, [currentPage]);

    return (
      <div className="carousel-container">
        <div
          className="carousel-slides"
          ref={carouselSlidesRef}
        >
          {pageContainers.map((pageTestimonials, pageIndex) => (
            <div key={pageIndex} className="carousel-page">
              <div className="carousel-grid">
                {pageTestimonials.map((testimonial) => (
                    <TestimonialDisplay 
                    key={testimonial.id}
                    testimonial={testimonial} 
                    styleType={styleType} 
                    />
                ))}
              </div>
            </div>
          ))}
        </div>
  
        {/* Carousel navigation controls */}
        <div className="carousel-controls">
          <button onClick={prevPage} className="carousel-button prev">
            <ChevronLeft size={40} />
          </button>
          <button onClick={nextPage} className="carousel-button next">
            <ChevronRight size={40} />
          </button>
        </div>
  
        {/* Carousel indicators */}
        <div className="carousel-indicators">
          {Array.from({ length: totalPages }).map((_, i) => (
            <div
              key={i}
              className={`indicator-dot ${currentPage === i + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(i + 1)}
            ></div>
          ))}
        </div>
      </div>
    );
  }
);

export default React.memo(MultiModalTestimonials);