// File: components/ShareButton.tsx
import React, { useState, forwardRef, useMemo } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import { Facebook, Twitter, LinkedIn, Email, Link } from '@mui/icons-material';
import '../css/ShareButton.css';

// Constants
const SNACKBAR_DURATION = 1500; // Auto-hide duration in milliseconds

// Slide Transition Component
const SlideTransition = forwardRef(function SlideTransition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Utility function for generating share URLs
const getShareUrl = (platform: string, url: string, title: string): string => {
  switch (platform) {
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    case 'twitter':
      return `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
    case 'email':
      return `mailto:?subject=${encodeURIComponent(`Check out this blog: ${title}`)}&body=${encodeURIComponent(`Check out this blog: ${url}`)}`;
    default:
      return '';
  }
};

// Props Interface
interface ShareButtonProps {
  url: string;
  title: string;
}

// ShareButton Component
const ShareButton: React.FC<ShareButtonProps> = ({ url, title }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackbarState, setSnackbarState] = useState({ open: false, message: '' });

  const shareUrls = useMemo(() => ({
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    twitter: `https://twitter.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
    email: `mailto:?subject=${encodeURIComponent(`Check out this blog: ${title}`)}&body=${encodeURIComponent(`Check out this blog: ${url}`)}`,
  }), [url, title]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleSnackbarClose = () => setSnackbarState({ ...snackbarState, open: false });

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setSnackbarState({ open: true, message: 'Link copied to clipboard!' });
    } catch (err) {
      console.error('Failed to copy link:', err);
      setSnackbarState({ open: true, message: 'Failed to copy link.' });
    }
    handleCloseMenu();
  };

  const handleShare = (platform: keyof typeof shareUrls) => {
    const shareUrl = shareUrls[platform];
    window.open(shareUrl, '_blank', 'noopener,noreferrer');
    handleCloseMenu();
  };

  return (
    <div>
      <Button
        id="share-button"
        variant="contained"
        color="primary"
        startIcon={<ShareIcon />}
        onClick={handleClick}
        className="share-button"
      >
        Share
      </Button>
      <Menu
        id="share-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'share-button' }}
        className="share-menu"
      >
        <MenuItem onClick={handleCopyLink}>
          <Link fontSize="small" className="menu-icon" />
          <span className="menu-text">Copy Link</span>
        </MenuItem>
        <MenuItem onClick={() => handleShare('facebook')}>
          <Facebook fontSize="small" className="menu-icon" />
          <span className="menu-text">Share on Facebook</span>
        </MenuItem>
        <MenuItem onClick={() => handleShare('twitter')}>
          <Twitter fontSize="small" className="menu-icon" />
          <span className="menu-text">Share on Twitter</span>
        </MenuItem>
        <MenuItem onClick={() => handleShare('linkedin')}>
          <LinkedIn fontSize="small" className="menu-icon" />
          <span className="menu-text">Share on LinkedIn</span>
        </MenuItem>
        <MenuItem onClick={() => handleShare('email')}>
          <Email fontSize="small" className="menu-icon" />
          <span className="menu-text">Share via Email</span>
        </MenuItem>
      </Menu>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={snackbarState.message}
        action={
          <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};


// ShareButtonContainer Component
export const ShareButtonContainer: React.FC<{ url: string; title: string; centered?: boolean }> = ({
  url,
  title,
  centered = false, // Default value
}) => (
  <div className={centered ? 'share-button-container-centered' : 'share-button-container'}>
    <ShareButton url={url} title={title} />
  </div>
);

export default ShareButton;
