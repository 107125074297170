import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "@/firebaseConfig";
import { createUserInBackend, logToBackendLogFile } from "../externalLayerAccessor/BackEndRequests";
import { addUserEmailToFirebaseUsersCollectionIfNew, doesUserEmailExistInFirebaseUsersCollection } from "@/externalLayerAccessor/firebaseAccessor";
import { LANDING_PAGE_UPON_SIGN_IN_PATH, NavigationUrlParamTags } from "@/utils/Constants";

const DEFAULT_MVP_GMAIL_PASSWORD: string = `P-22wd-gmail-346gF!_${Math.random().toString(36).substring(2, 10)}`;


async function handleNewUser(userData: { email: string; userName: string; password: string; invitationCode: string }): Promise<void> {
    const userCreatedSuccessfullyInBackend = await createUserInBackend(userData);
    if (!userCreatedSuccessfullyInBackend) {
        logToBackendLogFile(`Error creating user in backend for email: ${userData.email}`, "error");
        throw new Error("Could not create user in backend.");
    }

    await addUserEmailToFirebaseUsersCollectionIfNew(userData.email);
}

export async function signInOrSignUpWithGmail({ //TODO: This interface needs to be refactored, this function should stricly deal with sign-in and not have to know naything about navigation, setting error etc.
    isSignUp,
    navigate,
    setError,
    setIsSigningInorSigningUp,
}: {
    isSignUp: boolean;
    navigate: (path: string) => void;
    setError: (error: string) => void;
    setIsSigningInorSigningUp: (isSigningInorSigningUp: boolean) => void;
}): Promise<void> {
    setIsSigningInorSigningUp(true); // Toggle signing-in state if provided

    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        if (user && user.email) {
            const userData = {
                userName: user.email, // Use email as username
                email: user.email,
                password: DEFAULT_MVP_GMAIL_PASSWORD,
                invitationCode: "", // No invitation code for Gmail sign-in
            };
            const userAlreadyExists = await doesUserEmailExistInFirebaseUsersCollection(user.email);

            if(!userAlreadyExists) {
                handleNewUser(userData);
            }

            const navigationSource = isSignUp
                ? NavigationUrlParamTags.SIGN_UP_COMPONENT
                : NavigationUrlParamTags.SIGN_IN_COMPONENT;

            navigate(`${LANDING_PAGE_UPON_SIGN_IN_PATH}?source_component=${navigationSource}`);
            } else {
                throw new Error(
                    "An error occurred while creating your account. Please try again or contact support."
                );
            }
        } catch (error) {
            setError("An unexpected error occurred. Please try again.");
            logToBackendLogFile(`Error signing in or signing up with Gmail: ${error}`, "error");
        } finally {
            setIsSigningInorSigningUp(false); // Reset signing-in state
        }
}
