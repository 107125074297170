import React from 'react';
import TargetTechEmployersIcon from '../TargetTechEmployersIcon';
import { StyleType, Testimonial, TestimonialType, MultiModalTestimonialBoard } from '@/utils/MultiModalTestimonials';
import { TechCompanyLogoUrl } from '@/utils/Constants';

const sampleTestimonials: Testimonial[] = [
    {
        id: '1000',
        userDisplayName: 'Sonjay',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/wlUkzFxoETg?si=7Np3IpYE51N3D8z0",
        altText: "Podcast episode of Sonjay's Google Interview Experience",
        captionHtml: "My Google mocks with Coditioning were <strong>possibly the best investment I've made in my life</strong>. I  received detailed feedback on bad signals I was giving off without  knowing, and the sessions also helped me optimize for Google's  interviewing style. I cleared the coding rounds with positive feedback  in all rounds, and received an <strong>L3 SWE offer</strong>.",
        companyLogoUrl: TechCompanyLogoUrl.GOOGLE,
        companyName: 'Google',
    },
    {
        id: '2000',
        userDisplayName: 'Anonymous',
        type: TestimonialType.TEXT,
        text: "Coditioning's mock interview service completely changed how I approach interviews and I credit them with helping me get my Meta L3 offer. The idea of 'autopilot' taking over and guarding against this sounded abstract at first, but I experienced it firsthand in my interview. It nearly walked me into the reject pile, steering me toward solving the wrong problem based on a similar one I'd seen before. It took real focus and discipline to course-correct—something I credit to the preparation sessions at Coditioning. The coached interviews, especially with Nito, were a game-changer. They're miles ahead of what you get on other platforms, where mock interviewers often seem disengaged and just going through the motions. At Coditioning, the coaches are incredibly observant, passionate about teaching, and provide thoughtful feedback that actually helps you grow. I'm really happy with how my interviews turned out, and I feel bad that others pay up to three times the rate for far less value on other platforms. If you're serious about improving, Coditioning's sessions are worth every penny.",
        textHtml: "<p>Coditioning's mock interview service <strong>completely changed how I approach interviews, and I credit them with helping me get my Meta L3 offer</strong>. The idea of 'autopilot' taking over and guarding against this sounded abstract at first, but I experienced it firsthand in my interview. It nearly walked me into the reject pile, steering me toward solving the wrong problem based on a similar one I'd seen before. It took real focus and discipline to course-correct—something I credit to the preparation sessions at Coditioning.</p>  <p>The coached interviews, especially with Nito, were a <strong>game-changer.</strong> They're miles ahead of what you get on other platforms, where mock interviewers often seem disengaged and just going through the motions. <strong>At Coditioning, the coaches are incredibly observant, passionate about teaching, and provide thoughtful feedback that actually helps you grow.</strong></p>  <p>I'm really happy with how my interviews turned out, and I feel bad that others pay up to three times the rate for far less value on other platforms. <strong>If you're serious about improving, Coditioning's sessions are worth every penny.</strong></p>",
        companyLogoUrl: TechCompanyLogoUrl.META,
        companyName: 'Meta',
    },
    {
        id: '4000',
        userDisplayName: 'Bosiang.H',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/X9TNNWdJBJs?si=dBpo3m_UOTy0IGr8",
        altText: "Podcast episode of Bosiang's Amazon SDE II Interview Experience",
        captionHtml: "In this episode of the Coditioning podcast, we catch up with Bosiang, who recently went through the <strong>Amazon SDE II interview loop</strong>. Bosiang had 7 sessions with Coditioning (mock interviews & coaching) and ended up <strong>receiving an offer in the fall of 2024</strong>. Listen to this episode, where he shares his experience.",
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
        companyName: 'Amazon',
    },
    {
        id: '3000',
        userDisplayName: 'Anonymous Discord Workshop Attendee',
        type: TestimonialType.IMAGE,
        imageUrl: "https://coditioning3.wordpress.com/wp-content/uploads/2024/09/discord_amazon_testimonial.png.png?w=800",
        altText: "Discord Member Testimony for Human Interviewer Service",
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
        companyName: 'Amazon',
    },
    {
        id: '5000',
        userDisplayName: 'Diana',
        type: TestimonialType.VIDEO,
        videoEmbedUrl: "https://www.youtube.com/embed/Z0XGUQk3NO0?si=eo-yCxpNQ4gY8sM9",
        altText: "Video Testimonial of Interviewing Service from a Meta Employee",
        captionHtml: "In this episode of the Coditioning podcast, we meet Diana, who recently went through the <strong>META interview loop</strong> for a New Graduate Production Engineer role. Diana had 7 sessions with Coditioning (mock interviews & coaching) and ended up <strong>receiving an offer in the fall of 2024</strong>. Listen to this episode, where she shares her experience.",
        companyLogoUrl: TechCompanyLogoUrl.META,
        companyName: 'Meta',
    },
    {
        id: '6000',
        userDisplayName: 'R.Wama',
        type: TestimonialType.TEXT,
        text:'Got the Amazon offer. After bombing a bunch of interviews, it became clear that just grinding LeetCode wasn’t cutting it. I must have solved around 500 problems but I still sucked at interviews, and never got feedback from recruiters. The detailed feedback from Coditioning mocks were invaluable and really helped me improve. Just glad to be done with interviews. Thanks',
        textHtml: '<strong>Got the Amazon offer</strong>. After bombing a bunch of interviews, it became clear that just grinding LeetCode wasn’t cutting it. I must have solved around 500 problems but I still sucked at interviews, and never got feedback from recruiters. The detailed feedback from Coditioning mocks were invaluable and really helped me improve. Just glad to be done with interviews. Thanks',
        companyName: 'Amazon',
        companyLogoUrl: TechCompanyLogoUrl.AMAZON,
    },
    {
        id: '7000',
        userDisplayName: 'Sumanth',
        type: TestimonialType.TEXT,
        text: `I was referred by a friend and came at short notice to ask for a custom Python interview. I had to practice debugging actual Python code and making it production-ready. This isn't something
        I got much practice with when using LeetCode, but the service was really good. They uncovered so many gaps in my knowledge. I thought I understood generators and decorators, but boy was I wrong. Even some gaps in my knowledge of the basics were brought to light. Glad I did this mock.`
    },
    {
        id: '8000',
        userDisplayName: 'H.Yun',
        type: TestimonialType.TEXT,
        text: "I recently landed a job offer working on AI infrastructure. I just want to say thank you to all your resources and your mock feedback. I genuinely appreciate your time & effort!",
        companyName: 'AI Startup',
    },
];

interface SocialProofProps {
    // Custom props as needed
    disabled: boolean;
}

const SocialProof: React.FC<SocialProofProps> = (props) => {
    if (props.disabled) {
        return null;
    }
    return (
        <>
            <div className="social-proof-container landing-page-row">
                <h1 className='highlight-text'> Maximise your chances of securing offers from top employers:</h1>
                <TargetTechEmployersIcon
                    width="40%"
                    minWidth='300px'

                />

            </div>
            <div className="social-proof-container landing-page-row ">
                <h1 className='highlight-text'>Hone your interviewing skills through realistic mocks & coaching:</h1>
                {<MultiModalTestimonialBoard 
                    testimonials={sampleTestimonials}
                    itemsPerPage={6}
                    styleType={StyleType.STICKY_NOTE}
                />}
            </div>
        </>
    );
}

export default SocialProof;