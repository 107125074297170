import { useState, useEffect } from 'react';

// Enum for screen sizes
enum ScreenSize {
  mobile = 600,
  tablet = 1100,
}

// Enum for layout direction
enum LayoutDirection {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

// Enum for screen height classes
enum ScreenHeight {
  mobile = 'min-h-[220vh]',  // 220vh for mobile
  ipad = 'min-h-[160vh]',    // 160vh for iPad
  Large = 'min-h-[54vh]',    // 54vh for large screens
}

// Hook to track window size, layout direction, and dynamic height classes
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const [direction, setDirection] = useState<LayoutDirection>(LayoutDirection.Horizontal);
  const [heightClass, setHeightClass] = useState<string>('');

  useEffect(() => {
    // Function to update window size, layout direction, and dynamic height
    const updateWindowSize = () => {
      const width = window.innerWidth;
      let height = window.innerHeight;

      // Set layout direction based on screen width (e.g., mobile uses vertical layout)
      const newDirection = width < ScreenSize.tablet ? LayoutDirection.Vertical : LayoutDirection.Horizontal;
      setDirection(newDirection);

      // Set window size
      setWindowSize({
        width: window.innerWidth,
        height: height,  // Use updated height for iOS if applicable
      });

      // Dynamically assign height classes based on screen size
      if (width < ScreenSize.mobile) {
        setHeightClass(ScreenHeight.mobile); // Use mobile height class
      } else if (width >= ScreenSize.mobile && width < ScreenSize.tablet) {
        setHeightClass(ScreenHeight.ipad);   // Use iPad height class
      } else {
        setHeightClass(ScreenHeight.Large);  // Use large screen height class
      }
    };

    // Set initial window size if in the browser
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }

    // Add event listener to update window size, height, and direction on resize
    window.addEventListener('resize', updateWindowSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  return { windowSize, direction, heightClass };
};

export default useWindowSize;
