import { InterviewerPersonality, ProgrammingLanguage } from "../pages/Settings";



export const NOT_SIGNED_IN_USER_USER_ID = -Math.floor(Math.random() * 1000000000); // initialise with a negative number to avoid collision with signed in user ids which are positive. Randomness done so that it is unlikely to collide with another non-signed in user id and make mp tracking from logs easier
export const NOT_SIGNED_IN_USER_USER_EMAIL = "notSignedIn@coditioning.com";
export const EMAIL_TO_USER_ID_MAPPING: string = 'emailToUserIdMapping';
/*
Custom learning path
*/
export const LEARNING_PATH_COURSE_ID: number = 1;
export const LEARNING_PATH_MODULE_ID: number = 0;
export const LEARNING_PATH_SUBMODULE_ID: number = 0;


/*
Dimensions
*/
export const DEFAULT_CONTENT_CONTAINER_MIN_HEIGHT_IN_PX = 'auto';
export const DEFAULT_CONTENT_CONTAINER_MAX_HEIGHT_IN_PX = 'auto';

export const HOME_PAGE_PATH = '/';
export const CAREERS_PAGE_PATH = "/careers";
export const CONTACT_PAGE_PATH = "/contact";
export const ABOUT_PAGE_PATH = "/about";
export const TERMS_PAGE_PATH = "/terms";
export const PRIVACY_PAGE_PATH = "/privacy";
export const DASHBOARD_PAGE_PATH = "/dashboard";
export const JOURNEY_PAGE_PATH = "/journey";
export const PHASES_PAGE_PATH = "/phases";


export const LEARNING_PAGE_PATH = "/app/learning";
export const LEARNING_PAGE_COURSES_SUB_PATH = "courses";

export const CRASH_COURSE_PAGE_PATH = "/app/crashCourse";
export const DIAGNOSTICS_PAGE_PATH = "/app/diagnostics";
export const CV_SERVICES_PAGE_PATH = "/app/cv-services";
export const SIGN_UP_PAGE_PATH = "/signup";
export const SIGN_IN_PAGE_PATH = "/signin";
export const BLOG_PAGE_PATH = "/blog";
export const LEARNING_PATH_GENERATOR_PATH = "/app/learningPathGenerator";
export const LEARNING_PATH_PATH = "/app/learningPath";
export const LANDING_PAGE_UPON_SIGN_IN_PATH = LEARNING_PAGE_PATH;
export const AI_INTERVIEW_SELECTION_PAGE_PATH = "/app/interviewSelection";
export const AI_INTERVIEW_PAGE_PATH = "/app/aiInterview";
export const HUMAN_INTERVIEWER_COACH_PATH = "/app/humanInterviewerCoach";
export const MOCK_INTERVIEW_PAGE_PATH = "/app/mockInterview";
export const START_MENU_PAGE_PATH = DIAGNOSTICS_PAGE_PATH;
export const CODING_SANDBOX_PATH = "/app/codingSandbox";
export const SUBSCRIPTIONS_PAGE_PATH = "/app/subscriptions";
export const PAYMENT_SUCCESS_PAGE_PATH = `${SUBSCRIPTIONS_PAGE_PATH}?paymentStatus=success`;
export const PAYMENT_CANCELLED_PAGE_PATH = `${SUBSCRIPTIONS_PAGE_PATH}?paymentStatus=cancelled`;
export const WORKSHOPS_PAGE_PATH = "/workshops";

/*Sales and Growth Urls*/
export const DISCORD_URL = "https://discord.gg/eNYYvpRyBt";
export const CODITIONING_LOGO_URL = 'https://coditioning3.files.wordpress.com/2023/11/coditioning-logo-brain.png';
export const YOUTUBE_URL = "https://www.youtube.com/@Coditioning";
export const SYSTEM_DESIGN_WORKSHOP_URL = "https://calendly.com/coditioning/workshop-sys-design";
export const CODING_AND_BEHAVIOURAL_WORKSHOP_URL = "https://calendly.com/coditioning/coditioning-interview-prep-hangout";


/*Assessment urls*/
export const DIAGNOSTIC_ASSESSMENT_URL_NETWORKS_1 = "https://www.coditioning.com/app/learning/assessments/4"
export const DIAGNOSTIC_ASSESSMENT_URL_NETWORKS_2 = "https://www.coditioning.com/app/learning/assessments/5"
export const DIAGNOSTIC_ASSESSMENT_URL_OPERATING_SYSTEMS_1 = "https://www.coditioning.com/app/learning/assessments/6"
export const DIAGNOSTIC_ASSESSMENT_URL_OPERATING_SYSTEMS_2 = "https://www.coditioning.com/app/learning/assessments/7"

/*Navigation url param tags*/
export enum NavigationUrlParamTags {
  SIGN_IN_COMPONENT = "signIn",
  SIGN_UP_COMPONENT = "signUp",
}

/*Courses and Ids*/
export const SYSTEM_DESIGN_COURSE_ID = 6; // Note: This is coupling, but this Id is expected to never change in the backend. Ids should never change.

/*

Tech Company Logos
*/
export enum TechCompanyLogoUrl {
  GOOGLE = "https://coditioning3.wordpress.com/wp-content/uploads/2024/09/image.png?w=100",
  GOOGLE_FULL_LOGO = "https://coditioning3.wordpress.com/wp-content/uploads/2024/12/google-logo.png?w=512",
  AMAZON = "https://coditioning3.wordpress.com/wp-content/uploads/2024/09/image-4.png?w=300",
  META = "https://coditioning3.wordpress.com/wp-content/uploads/2024/10/meta-logo.png?w=300",
  MICROSOFT = "https://coditioning3.wordpress.com/wp-content/uploads/2024/12/microsoft-logo-1.png?w=1024",
  NETFLIX = "https://coditioning3.wordpress.com/wp-content/uploads/2024/01/netflix-logo.png?w=300",
  TWO_SIGMA ="https://coditioning3.wordpress.com/wp-content/uploads/2024/01/two-sigma-logo-svg.png?w=300",
  UBER = "https://coditioning3.wordpress.com/wp-content/uploads/2024/01/uber-logo.png?w=300",
  CITADEL = "https://coditioning3.wordpress.com/wp-content/uploads/2024/01/citadel-logo.png?w=300",
  FAANG = "https://coditioning3.files.wordpress.com/2024/01/combined-tech-company-logos.png?w=798",
}


/*
Constants
*/
export const SUPPORT_CONTACT_EMAIL = "support@coditioning.com";
export const CAREERS_CONTACT_EMAIL = "careers@coditioning.com";
export const CODITIONING_WORKSHOP_OR_HANGOUT_BANNER_MSG = `Aiming for FAANG and other SWE roles? Interview coming up? We can help you get interview-ready? Join our FREE weekly hangout/workshop sessions`;

//email JS
export const DEFAULT_EMAIL_JS_PUBLIC_KEY = 'MztFpffJJWfpnVeEJ';
export const CODITIONING_OUTLOOK_SERVICE_ID = 'service_s2v0me8';
export const FEATURE_FEEDBACK_TEMPLATE_ID = 'template_ico5htk';
export const WELCOME_EMAIL_TEMPLATE_ID = 'template_m767dcb';


//NEBUG: MVP CODE NEEDS REFACTORING
export const DONE_CODING_BUTTON_TEXT = "Done coding";
export const SKIP_BUTTON_TEXT = "Skip question";
export const REVEAL_ANSWERS_BUTTON_TEXT = "Reveal answers";


/*
Classes
*/
export class TelephoneOrOnsiteInterviewState {
  constructor(
    public candidateName: string,
    public programmingLanguage: string,
    public interviewerPersonality: InterviewerPersonality,
    public candidateAttemptEvaluationByPhaseIndex: { [phaseIndex: number]: CandidateAttemptEvaluationByPhaseIndex },
    public interviewScriptPhases: Phase[],
  ) { }
}

export class TakeHomeAssessmentInterviewState {
  candidateName: string;
  programmingLanguage: string; // nebug: make enum
  interviewerPersonality: string;
  codingTaskDescription: string;
  candidatesCode: string;
  acceptableAnswers?: string;

  constructor(
    candidateName: string,
    programmingLanguage: string,
    interviewerPersonality: string,
    codingTaskDescription: string,
    candidatesCode: string,
    acceptableAnswers?: string
  ) {
    this.candidateName = candidateName;
    this.programmingLanguage = programmingLanguage;
    this.interviewerPersonality = interviewerPersonality;
    this.codingTaskDescription = codingTaskDescription;
    this.candidatesCode = candidatesCode;
    this.acceptableAnswers = acceptableAnswers;
  }
}

export enum InterviewMode {
  INTERVIEW = "interview",
  COACH = "coach",
}

export enum InterviewStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  ENDED = "ENDED"
}

export enum InterviewType {
  TECHNICAL = 'Technical',
  // BEHAVIORAL = 'Behavioural',
  // SYSTEM_DESIGN = 'System Design', NEBUG: TO ENABLE WHEN AVAILABLE
}

export type Company = {
  name: string; //NEBUG; type should be CompanyName
  roleTypes: RoleType[];
};

export type RoleType = {
  name: string;// NEBUG: type should be RoleTypeName
  levels: string[];
};

export enum RoleTypeName {
  SOFTWARE_ENGINEER = "Software Engineer",
  DATA_SCIENTIST = "Data Scientist",
  MACHINE_LEARNING_ENGINEER = "Machine Learning Engineer",


}
export enum CompanyName {
  GENERIC_TECH_COMPANY = "Generic Tech Company",
  GOOGLE = "Google",
  META = "Meta",
  OPENAI = "OpenAI",
  AMAZON = "Amazon",
  MICROSOFT = "Microsoft",
  NETFLIX = "Netflix",
  TWO_SIGMA = "Two Sigma",
  UBER = "Uber",
  CITADEL = "Citadel",
  FAANG = "FAANG",
  OTHER_COMPANY = "Other Company",
}
export const companies: Company[] = [
  {
    name: CompanyName.GENERIC_TECH_COMPANY,
    roleTypes: [
      //senior software engineer
      {
        name: 'Software Engineer',
        levels: ['Junior', 'Senior']
      },
    ],
  },
  {
    name: CompanyName.GOOGLE,
    roleTypes: [
      {
        name: 'Software Engineer',
        levels: [],
      },
      // {
      //   name: 'Data Scientist',
      //   levels: [],
      // },
      // Add other role types and levels for Google as needed
    ],
  },
  {
    name: CompanyName.META,
    roleTypes: [
      {
        name: 'Software Engineer',
        levels: [],
      },
      // {
      //   name: 'Data Scientist',
      //   levels: [],
      // },
      // Add other role types and levels for Meta as needed
    ],
  },

  {
    name: CompanyName.OPENAI,
    roleTypes: [
      {
        name: 'Software Engineer',
        levels: [],
      },
    ],
  },

  {
    name: CompanyName.AMAZON,
    roleTypes: [
      {
        name: 'Software Development Engineer',
        levels: [],
      },
    ],
  },
  // Add other companies with their respective role types and levels
];

export class Hint {
  hint: string;

  constructor(hint: string) {
    this.hint = hint;
  }
}

export enum ChallengeType {
  CODING = "coding",
  KNOWLEDGE = "knowledge", // can be used for knowledge question requiring reasoning if objective is to test foundational knowledge
  EXPLAIN_CODE = "explain_code",
  Algorizing = "algorizing", // means to come up with an algorithm, but not necessarily code it
  LOW_LEVEL_DESIGN = "low_level_design",
  SYSTEM_DESIGN = "system_design",
  TUTORIAL = "tutorial",
}


export class ReferenceSolution {
  referenceSolutionLabel?: string;
  referenceSolution: string;
  referenceSolutionIsCode: boolean;
  referenceSolutionExplanationQuick: string | null;
  referenceSolutionExplanationThoughtProcessHtml?: string | null;
  referenceSolutionExplanationExplainLikeImFive?: string | null;
  referenceSolutionProgrammingLanguage: ProgrammingLanguage | null;

  constructor({
    referenceSolutionLabel,
    referenceSolution,
    referenceSolutionIsCode,
    referenceSolutionExplanation,
    referenceSolutionProgrammingLanguage,
  }: {
    referenceSolutionLabel?: string;
    referenceSolution: string;
    referenceSolutionIsCode: boolean;
    referenceSolutionExplanation: string | null;
    referenceSolutionProgrammingLanguage: ProgrammingLanguage | null;
  }) {
    this.referenceSolutionLabel = referenceSolutionLabel;
    this.referenceSolution = referenceSolution;
    this.referenceSolutionIsCode = referenceSolutionIsCode;
    this.referenceSolutionExplanationQuick = referenceSolutionExplanation;
    this.referenceSolutionProgrammingLanguage = referenceSolutionProgrammingLanguage;
  }
}


export interface CodeStub {
  value: string;
  isCode: boolean;
}

export class Phase {
  id: number;
  phaseTimeInMilliSeconds: number;
  challenge: string;
  challengeType: ChallengeType;
  codeStub?: CodeStub; // NITO TODO: consider if worthwhile having language specific code stubs (return must justify the complexity)
  language?: string;
  acceptableAnswer: string | undefined;
  referenceSolutions?: ReferenceSolution[] = []; //NITO-1: make required once all questions have reference solutions
  hints: Hint[];
  // NITO: add optional allowed set of programming languages
  required: boolean;

  constructor(
    id: number,
    phaseTime: number,
    challenge: string,
    challengeType: ChallengeType,
    hints: Hint[],
    required: boolean,
    codeStub?: CodeStub,
    language?: string,
    referenceSolutions?: ReferenceSolution[],
  ) {
    this.id = id;
    this.phaseTimeInMilliSeconds = phaseTime;
    this.challenge = challenge;
    this.challengeType = challengeType;
    this.hints = hints;
    this.required = required;
    this.codeStub = codeStub;
    this.language = language;
    this.referenceSolutions = referenceSolutions ?? [];
  }
}


export enum ChatMessageSender {
  System = "system",
  Candidate = "candidate",
  Interviewer = "interviewer",
  InterviewerCoach = "interviewerCoach",
}

export enum InterviewDurations {
  TWENTY_MINUTES = 20,
  TWENTY_FIVE_MINUTES = 25,
  THIRTY_MINUTES = 30,
  THIRTY_FIVE_MINUTES = 35,
  FORTY_FIVE_MINUTES = 45,
  SIXTY_MINUTES = 60,
}

export class UserExperienceFeedback {
  userFeedbackRating: number;
  recommendPlatform: string;
  likedMostFeedback: string;
  improvementFeedback: string;
  userEmail: string;

  constructor(userFeedbackRating: number, recommendPlatform: string, likedMostFeedback: string,
    improvementFeedback: string, userEmail: string
  ) {
    this.userFeedbackRating = userFeedbackRating;
    this.recommendPlatform = recommendPlatform;
    this.likedMostFeedback = likedMostFeedback;
    this.improvementFeedback = improvementFeedback;
    this.userEmail = userEmail;
  }
}



/*
Models that have to match with backend
*/
export enum CandidatesMessageIntent {
  ATTEMPT_TO_ANSWER_QUESTION = "attempt_to_answer_question",
  DECLARE_NOT_KNOWING_ANSWER = "declare_not_knowing_answer",
  REQUEST_FOR_CLARIFICATION = "request_for_clarification",
  REQUEST_TO_SKIP = "request_to_skip",
  REQUEST_FOR_HINT = "request_for_hint",
  UNRELATED_QUESTION = "unrelated_question",
  UNKNOWN = "unknown",
}

export class CandidateAttemptEvaluationByPhaseIndex {
  constructor(
    public phaseId: number,
    public attemptCorrectnessByAttempt: boolean[],
    public interviewerFeedbackByAttempt: string[],
  ) { }
}

export enum Environment {
  LOCAL_DEV = 'local_development',
  DEV = 'development',
  UAT = 'uat',
  PROD = 'production',
}

export enum AccessLevel {
  Standard = 'standard',
  Premium = 'premium',
  ComingSoon = 'coming_soon',
}

export enum AssessmentType {
  TruthOrDespair = 'truth_or_despair',
  TimedCoding = 'timed_coding',
  EfficientAlgorithm = 'efficient_algorithm',
  SKIP_DUMMY_CHALLENGE = "skip_dummy_challenge", // signal for component to auto-skip question, added as temporary fix for an issue that occurs when transitoning btween challenges of different challenge types, helps resolve issue for some reason
  NOT_AN_ASSESSMENT = "not_an_assessment",
  SYSTEM_DESIGN = "system_design",
}

export enum Difficulty {
  Straightforward = 'straightforward',
  ModeratelyChallenging = 'moderately_challenging',
  Challenging = 'challenging',
}

export enum Category {
  Badge = 'badge',
  ExperiencePoints = 'experience_points',
  SpecialAbility = 'special_ability',
}

export enum Language {
  Python = "python",
  'C++' = "cpp",
  JavaScript = "javascript",
  Java = "java",
  Go = "go",
  'C#' = "csharp",
  C = "c",
  Typescript = 'typescript',
  Swift = 'swift',
  Kotlin = 'kotlin'
  // Ensure to update `LANGUAGE_DISPLAY_NAMES` until we introduce a way that automatically maps language to display name
}

export enum ProgressStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
}

export enum SpecialAbilityName {
  MentorsWhisper = "MENTORS_WHISPER",
  TimeDilation = "TIME_DILATION",
  SecondWind = "SECOND_WIND",
  DivineInspiration = "DIVINE_INSPIRATION",
  CognitiveBoost = "COGNITIVE_BOOST",
  TimeWarp = "TIME_WARP",
  DoubleEdge = "DOUBLE_EDGE",
  BugScanner = "BUG_SCANNER",
  Shield = "SHIELD",
  InstantFeedback = "INSTANT_FEEDBACK",
}

export enum AchievementName {
  FirstBlood = "FirstBlood",
  ConsistencyKing = "ConsistencyKing",
  BugBuster = "BugBuster",
  Perfectionist = "Perfectionist",
  Pathfinder = "Pathfinder",
  NoviceBadge = "NoviceBadge",
  JourneymanBadge = "JourneymanBadge",
  MasterBadge = "MasterBadge",
  SpecialistBadge = "SpecialistBadge",
  ConsistencyBadge = "ConsistencyBadge",
  PolyglotBadge = "PolyglotBadge",
  SpeedDemonBadge = "SpeedDemonBadge",
}

export enum CriteriaForAchieving {
  Speed = 'speed',
  Completion = 'completion',
  Accuracy = 'accuracy',
  Streak = 'streak'
}

export interface Reward {
  reward_id: number;
  category: Category;
  criteria_for_achieving: CriteriaForAchieving;
}

export interface CodeBlockByLanguage {
  Python: string;
  Java: string;
  'C++': string;
  C: string;
  'C#': string;
  JavaScript: string;
  Go: string;
  Swift: string;
  TypeScript: string;
  Kotlin: string;
  Ruby?: string | undefined;
}

export enum UnitConceptTags {
  // Data structures and algos
  Queue = 'Queue',
  String = 'String',
  Array = 'Array',
  Stack = 'Stack',
  Matrix2DArray = 'Matrix/2-D array',
  HashFunction = 'Hash Function',
  HashTable = 'Hash Table',
  BinarySearch = 'Binary Search',
  Sorting = 'Sorting',
  PriorityQueue = 'Priority Queue',
  LinkedList = 'Linked List',
  DoublyLinkedList = 'Doubly-Linked List',
  Recursion = 'Recursion',
  Tree = 'Tree',
  BinarySearchTree = 'Binary Search Tree',
  DivideAndConquer = 'Divide and Conquer',
  Heap = 'Heap',
  Trie = 'Trie',
  Graph = 'Graph',
  DepthFirstSearch = 'Depth-First Search',
  BreadthFirstSearch = 'Breadth-First Search',
  TopologicalSort = 'Topological Sort',
  DynamicProgramming1D = 'Dynamic Programming (1-D)',
  DynamicProgramming2D = 'Dynamic Programming (2-D)',
  DynamicProgramming3D = 'Dynamic Programming (3-D)',
  BitManipulation = 'Bit Manipulation',
  Bitmask = 'Bitmask',
  ConcurrencyAndParallelAlgorithms = 'Concurrency & parallel algorithms',
  AsymptoticRuntimeComplexity = 'Asymptotic Runtime complexity',
  AsymptoticSpaceComplexity = 'Asymptotic Space complexity',
  Memoization = 'Memoization',

  // Low-level design
  LowLevelDesign = 'Low-level Design',
  Encapsulation = 'Encapsulation',

  // System design
  SystemDesign = 'System Design',
  DatabaseSelection = 'Database Selection',
  DistributedCache = 'Distributed Cache',
  ApiDesign = 'API Design',
  EventStreaming = 'Event Streaming',

  // Production Engineering
  Networks = 'Networks',
  OperatingSystems = 'Operating Systems',
}


export interface Lesson {
  title: string;
  markdown?: string;
  html?: string;
  video_url?: string;
}

export interface Tutorial {
  lesson: Lesson;
}

export interface Challenge {
  challenge_id: number;
  title: string;
  tutorial?: Tutorial;
  challenge_type: ChallengeType;
  assessment_type: AssessmentType;
  problem_statement: string;
  assessment_options: string[];
  penalty: number;
  time_limit_in_minutes: number;
  max_attempts: number;
  acceptable_answers: string[];
  hints: string[];
  test_cases?: ChallengeTestCase[]; // NITO: make required once all questions have test cases
  difficulty: Difficulty
  key_associations?: string[];
  rewards?: Reward[];
  code_stub_by_language?: CodeBlockByLanguage;
  solution_by_language?: CodeBlockByLanguage;
  spaced_repetition_challenges?: Challenge[];
}

export interface Unit {
  unit_id: number;
  access_level: AccessLevel
  title: string;
  challenge: Challenge;
  explanations: ExplanationsInterface
  prerequisite_unit_ids?: number[];
  tags: UnitConceptTags[];
}

/*
summarises the results an attempt of a unit
*/
export interface UnitAttemptReport {
  unit: Unit;
  answeredCorrectly: boolean | null; // null if not attempted
}
export interface Submodule {
  submodule_id: number;
  title: string;
  estimated_completion_time_in_minutes?: number;
  units: Unit[];
}

export interface Module {
  module_id: number;
  title: string;
  submodules: Submodule[];
}

export interface Course {
  course_id: number;
  title: string;
  modules: Module[];
  thumbnail_url?: string;
}

export interface MinifiedCourse {
  course_id: number;
  title: string;
  thumbnail_url?: string;
}

export interface Assessment {
  assessment_id: number;
  title: string;
  estimated_completion_time_in_minutes: number;
  number_of_questions_to_answer: number;
  units: Unit[];
}

/*
summarises the results of an assessment attempt
*/
export interface AssessmentAttemptReport {
  assessment: Assessment | null;
  unitAttemptReports: UnitAttemptReport[];
}


export interface ChallengeDescriptionInterface {
  moduleTitle?: string;
  submoduleTitle?: string;
  unit: Unit;
  challengeTitle: string;
  challengeType: ChallengeType;
  assessmentType: AssessmentType;
  problemStatement: string;
  penalty: number;
  hints: string[];
  difficulty: Difficulty;
  rewards?: Reward[];
  keyAssociations: string[];
  totalNumChallengesInScope: number;
}

export interface ExplanationsInterface {
  test_cases_markdown?: string | null;
  quick_html?: string | null;
  explain_like_im_five_years_old_html?: string | null;
  thought_process_html?: string | null;
  example_based_html?: string | null;
  quick_markdown?: string | null;
  explain_like_im_five_years_old_markdown?: string | null;
  thought_process_markdown?: string | null;
  diagram_url?: string | null;
  example_based_markdown?: string | null;
}


export enum ExecutionStatus {
  Accepted = "ACCEPTED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Rejected_Insufficient_Tokens = "REJECTED_INSUFFICIENT_TOKENS",
}

export enum TestCaseExecutionStatus {
  IN_QUEUE = "IN_QUEUE",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  WRONG_ANSWER = "WRONG_ANSWER",
  TIME_LIMIT_EXCEEDED = "TIME_LIMIT_EXCEEDED",
  COMPILATION_ERROR = "COMPILATION_ERROR",
  RUNTIME_ERROR = "RUNTIME_ERROR",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  EXEC_FORMAT_ERROR = "EXEC_FORMAT_ERROR",

}

export interface ChallengeTestCase {
  testcase_id: number;
  input: string;
  expected_output: string;
}

export interface TestCase {
  stdin: string;
  expected_output: string;
}

export interface ExecutionResult {
  stdin: string | null;
  stderr: string | null;
  execution_time_seconds: string;
  memory_usage_bytes: number | null;
  expected_output: string | null;
  actual_output: string | null;
  token: string;
  test_case_status: TestCaseExecutionStatus;
}

export interface TestCaseExecutionResponse {
  executionStatus: ExecutionStatus;
  results: {
    allTestCasesPassed: boolean;
    testCases: ExecutionResult[];
  };
}

export interface TestCaseExecutionRequest {
  user_id: number;
  source_code: string;
  language: string;
  test_cases: TestCase[];
}

export interface AssessmentData {
  unitTitle: string;
  challengeTitle: string;
  challengeId: number;
  challengeTimeLimit: number;
  rightAnswer?: boolean | null;
  difficulty: Difficulty;
  challengeType: ChallengeType;
  knowledgeAnswer?: string[];
  codingAnswer?: CodeBlockByLanguage;
}

export interface UserAssessmentAnswers {
  challengeId: number;
  rightAnswer?: boolean | null;
}


export interface NewUser {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface UserResponse {
  userId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserProgress {
  course_id: number;
  module_id?: number;
  submodule_id?: number;
  unit_id?: number;
  status: ProgressStatus;
}

export interface ExperiencePoints {
  user_id: number;
  experience_points: number;
}

export interface ExperiencePointsOffset {
  offset: number;
}

export interface UserSpecialAbility {
  ability_name: SpecialAbilityName;
  ability_description: string;
  remaining_units: number;
}

export interface UserAchievement {
  name: AchievementName;
  description: string;
  achieved_at: string; // Format: date-time
}



//////////////////////////
// local storage keys
//////////////////////////
export const LOCAL_STORAGE_LEARNING_PATH = "learningPath";
export const LOCAL_STORAGE_SPACED_REPETITION_KEY = "completedChallengeIDs";
export const LOCAL_STORAGE_LEARNING_PATH_USER_ASSESSMENT_BY_CONCEPT = "concepts";
export const LOCAL_STORAGE_PREFERRED_PROGRAMMING_LANGUAGE_KEY = "preferred-programming-language";

export interface UserEntitlements { // TODO: we should most likely decomission this
  isAiInterviewerFeatureEnabled: boolean;
  // Add more features as needed
}

export interface InterviewerCoachInteractionContext {
  userId: number;
  userEmail: string;
  candidateName: string;
  chatHistory: ChatMessage[];
  challenge: Challenge;
  candidatesCode: string;
  programmingLanguage: ProgrammingLanguage;
}

export class ChatMessage { // This class has a backend counterpart, so if you change it, change it there too
  sender: string;
  recipient: string;
  timeSent: string;
  message: string;
  ////////////////////////////////////////////////
  // Properties only relevant in response messages
  ////////////////////////////////////////////////
  candidatesAnswerWasAccepted?: boolean;
  interviewersClassificationOfCandidatesIntent?: CandidatesMessageIntent;

  ////////////////////////////////////////////////
  //Other optional properties
  ////////////////////////////////////////////////
  challengeType?: ChallengeType;

  constructor(
    sender: string,
    recipient: string,
    timeSent: string,
    message: string,
    candidatesAnswerWasAccepted?: boolean,
    interviewersClassificationOfCandidatesIntent?: CandidatesMessageIntent,
    challengeType?: ChallengeType,

  ) {
    this.sender = sender;
    this.recipient = recipient;
    this.timeSent = timeSent;
    this.message = message;
    if (candidatesAnswerWasAccepted !== undefined) {
      this.candidatesAnswerWasAccepted = candidatesAnswerWasAccepted;
    }

    if (challengeType !== undefined) {
      this.challengeType = challengeType;
    }

    if (interviewersClassificationOfCandidatesIntent !== undefined) {
      this.interviewersClassificationOfCandidatesIntent = interviewersClassificationOfCandidatesIntent;
    }
  }

  static createWithChallengeType(
    sender: string,
    recipient: string,
    timeSent: string,
    message: string,
    challengeType: ChallengeType
  ): ChatMessage {
    let chatMessage = new ChatMessage(
      sender,
      recipient,
      timeSent,
      message
    );
    chatMessage.challengeType = challengeType;
    return chatMessage;
  }

}