import React, { useState, useEffect } from "react";
import DownArrow from "../img/svg/downArrow.svg";
import "../css/FAQs.css";


export interface FaqItem {
  question: string;
  answerHtml: string;
}

interface FAQItemProps extends FaqItem {
  isOpen: boolean;
  toggle: () => void;
}

interface FaqsProps {
  faqs: FaqItem[];
  itemsPerPage?: number;
}

// Function to generate structured data (JSON-LD) for FAQ schema
const generateFaqSchema = (faqs: FaqItem[]) => {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answerHtml
      }
    }))
  };
};

const FAQItem: React.FC<FAQItemProps> = ({ question, answerHtml, isOpen, toggle }) => {

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`} onClick={toggle}>
      <div className="question">
        <h3>{question}</h3>
        <img
          src={DownArrow}
          alt="Arrow icon to toggle answer visibility"
          width="20px"
          height="20px"
          className="arrow"
          style={{
            transform: isOpen ? "rotate(180deg)" : "none",
            transition: "transform 0.3s",
          }}
        />
      </div>
      {isOpen && (
        <div className="answer">
          <div dangerouslySetInnerHTML={{ __html: answerHtml }} />
        </div>
      )}
    </div>
  );
};

const FAQs: React.FC<FaqsProps> = ({ faqs, itemsPerPage = 4 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [openedFaqIndex, setOpenedFaqIndex] = useState<number | null>(null);
  const totalPages = Math.ceil(faqs.length / itemsPerPage);
  const start = currentPage * itemsPerPage;
  const currentFaqs = faqs.slice(start, start + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
    setOpenedFaqIndex(null);
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    setOpenedFaqIndex(null);
  };

  // Generate and insert FAQ structured data (JSON-LD)
  useEffect(() => {
    const faqSchema = generateFaqSchema(faqs);
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(faqSchema);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [faqs]);

  return (
    <>
      <div className="FAQ">
        <h2>Frequently Asked Questions:</h2>
        <div className="questions">
          {currentFaqs.map((faq, index) => {
            const faqIndex = start + index;
            return (
              <FAQItem 
                key={faqIndex} 
                {...faq} 
                isOpen={openedFaqIndex == faqIndex}
                toggle={() => setOpenedFaqIndex(openedFaqIndex == faqIndex ? null : faqIndex)}
              />
            )})}
        </div>
      </div>
      <div className="pagination-controls">
        <button onClick={handlePrevPage} disabled={currentPage === 0}>
          Previous
        </button>
        <span>
          Page {currentPage + 1} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
          Next
        </button>
      </div>
    </>
  );
};

export default FAQs;